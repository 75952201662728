import * as i0 from '@angular/core';
import { inject, ElementRef, Component, ChangeDetectionStrategy, input, computed, viewChild, effect, HostListener, NgModule, signal, Injectable, model, output, ApplicationRef, createComponent, isSignal, OutputEmitterRef, ChangeDetectorRef, contentChildren, assertInInjectionContext, Injector, DestroyRef, HostBinding, contentChild, TemplateRef, Renderer2, Directive, viewChildren } from '@angular/core';
import { DatePipe, NgTemplateOutlet } from '@angular/common';
import { SIGNAL } from '@angular/core/primitives/signals';
const _c0 = ["*"];
const _c1 = [[["", "icon", ""]], [["", "title", ""]], "*", [["", "content", ""]]];
const _c2 = ["[icon]", "[title]", "*", "[content]"];
function SparkleAlertComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 3);
    i0.ɵɵtext(1, "info");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_7_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 3);
    i0.ɵɵtext(1, "info");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 3);
    i0.ɵɵtext(1, "warning");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_9_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 3);
    i0.ɵɵtext(1, "warning-octagon");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_10_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 3);
    i0.ɵɵtext(1, "check-circle");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_11_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 3);
    i0.ɵɵtext(1, "question");
    i0.ɵɵelementEnd();
  }
}
function SparkleAlertComponent_Conditional_15_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "spk-icon", 7);
    i0.ɵɵlistener("click", function SparkleAlertComponent_Conditional_15_Template_spk_icon_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.removeAlert());
    });
    i0.ɵɵtext(1, "plus");
    i0.ɵɵelementEnd();
  }
}
const _c3 = ["alerts"];
const _c4 = ["scroller"];
function SparkleAlertContainerComponent_For_4_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 9);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const alert_r2 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", alert_r2.content, " ");
  }
}
function SparkleAlertContainerComponent_For_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-alert", 7, 1)(2, "div", 8);
    i0.ɵɵtext(3);
    i0.ɵɵelementEnd();
    i0.ɵɵtemplate(4, SparkleAlertContainerComponent_For_4_Conditional_4_Template, 2, 1, "div", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const alert_r2 = ctx.$implicit;
    const $index_r3 = ctx.$index;
    const ctx_r3 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(alert_r2.type);
    i0.ɵɵstyleProp("transition-delay", ctx_r3.transitionDelay($index_r3, true));
    i0.ɵɵclassProp("animate-in", alert_r2.animateIn)("animate-out", alert_r2.animateOut)("is-hidden", ctx_r3.alertHistoryIsHidden() && !alert_r2.isOpen);
    i0.ɵɵproperty("id", alert_r2.id)("alertService", ctx_r3.alertService());
    i0.ɵɵadvance(3);
    i0.ɵɵtextInterpolate(alert_r2.title);
    i0.ɵɵadvance();
    i0.ɵɵconditional(alert_r2.content ? 4 : -1);
  }
}
const _c5 = ["dialogRef"];
function SparkleDialogComponent_Conditional_4_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 4);
    i0.ɵɵlistener("click", function SparkleDialogComponent_Conditional_4_Template_div_click_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.isOpen.set(false));
    });
    i0.ɵɵelementEnd();
  }
}
const _c6 = ["spk-button", ""];
const _c7 = ["colorCanvas"];
const _c8 = ["triggerRef"];
const _c9 = ["popoverRef"];
const _c10 = [[["", "trigger", ""]], "*"];
const _c11 = ["[trigger]", "*"];
const _c12 = [[["label"]], [["", "prefix", ""]], [["", "textPrefix", ""]], [["input"]], [["", "popoverContent", ""]], [["textarea"]], [["", "textSuffix", ""]], [["", "suffix", ""]], [["", "error", ""]], [["", "hint", ""]]];
const _c13 = ["label", "[prefix]", "[textPrefix]", "input", "[popoverContent]", "textarea", "[textSuffix]", "[suffix]", "[error]", "[hint]"];
const _c14 = () => ({
  closeOnButton: false,
  closeOnEsc: true
});
const _c15 = ["daysRef"];
function SparkleDatepickerComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" - ", ctx_r0.getMonthName(ctx_r0.getLastVisibleMonth()), " ");
  }
}
function SparkleDatepickerComponent_For_13_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const day_r2 = ctx.$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(day_r2);
  }
}
function SparkleDatepickerComponent_For_13_For_7_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 2, 1);
    i0.ɵɵlistener("click", function SparkleDatepickerComponent_For_13_For_7_Template_div_click_0_listener() {
      const calDate_r4 = i0.ɵɵrestoreView(_r3).$implicit;
      const elementRef_r5 = i0.ɵɵreference(1);
      const ctx_r0 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r0.setDate(calDate_r4, elementRef_r5));
    });
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const calDate_r4 = ctx.$implicit;
    const monthOffset_r6 = i0.ɵɵnextContext().$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r0.isDateSelected(calDate_r4));
    i0.ɵɵclassProp("out-of-scope", !ctx_r0.isCurrentMonth(calDate_r4, monthOffset_r6));
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1(" ", calDate_r4.getDate(), " ");
  }
}
function SparkleDatepickerComponent_For_13_Conditional_8_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "article", 7);
    i0.ɵɵelement(1, "div", 9);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵstyleMap(ctx_r0.selectedDateStylePosition());
  }
}
function SparkleDatepickerComponent_For_13_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 5)(1, "nav", 6);
    i0.ɵɵrepeaterCreate(2, SparkleDatepickerComponent_For_13_For_3_Template, 2, 1, "div", null, i0.ɵɵrepeaterTrackByIndex);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(4, "div", 7, 0);
    i0.ɵɵrepeaterCreate(6, SparkleDatepickerComponent_For_13_For_7_Template, 3, 5, "div", 8, i0.ɵɵrepeaterTrackByIndex);
    i0.ɵɵtemplate(8, SparkleDatepickerComponent_For_13_Conditional_8_Template, 2, 2, "article", 7);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const monthOffset_r6 = ctx.$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵrepeater(ctx_r0.weekdays());
    i0.ɵɵadvance(4);
    i0.ɵɵrepeater(ctx_r0.getMonthDates(monthOffset_r6));
    i0.ɵɵadvance(2);
    i0.ɵɵconditional(!ctx_r0.asRange() ? 8 : -1);
  }
}
const _c16 = ["inputWrap"];
const _c17 = [[["label"]], [["", "prefix", ""]], [["", "textPrefix", ""]], [["input"]], [["", "textSuffix", ""]], [["", "suffix", ""]]];
const _c18 = ["label", "[prefix]", "[textPrefix]", "input", "[textSuffix]", "[suffix]"];
function SparkleDatepickerInputComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵlistener("click", function SparkleDatepickerInputComponent_Conditional_6_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.open($event));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate1(" ", ctx_r2._maskedDate(), " ");
  }
}
function SparkleDatepickerInputComponent_Conditional_13_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "spk-datepicker", 9);
    i0.ɵɵlistener("dateChange", function SparkleDatepickerInputComponent_Conditional_13_Template_spk_datepicker_dateChange_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onDateChange($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r2.styleClasses());
    i0.ɵɵproperty("date", ctx_r2.internalDate());
  }
}
function SparkleDatepickerInputComponent_ng_template_14_Template(rf, ctx) {}
const _c19 = ["startDate"];
const _c20 = ["endDate"];
function SparkleDateRangeInputComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    const _r2 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵlistener("click", function SparkleDateRangeInputComponent_Conditional_6_Template_div_click_0_listener($event) {
      i0.ɵɵrestoreView(_r2);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.open($event));
    });
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    let tmp_2_0;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate2(" ", (tmp_2_0 = ctx_r2._maskedStartDate()) !== null && tmp_2_0 !== undefined ? tmp_2_0 : "N/A", " - ", (tmp_2_0 = ctx_r2._maskedEndDate()) !== null && tmp_2_0 !== undefined ? tmp_2_0 : "N/A", " ");
  }
}
function SparkleDateRangeInputComponent_Conditional_13_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "spk-datepicker", 8);
    i0.ɵɵlistener("dateChange", function SparkleDateRangeInputComponent_Conditional_13_Template_spk_datepicker_dateChange_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onStartDateChange($event));
    })("endDateChange", function SparkleDateRangeInputComponent_Conditional_13_Template_spk_datepicker_endDateChange_0_listener($event) {
      i0.ɵɵrestoreView(_r4);
      const ctx_r2 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r2.onEndDateChange($event));
    });
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r2.classes);
    i0.ɵɵproperty("date", ctx_r2.startDate())("endDate", ctx_r2.endDate())("monthsToShow", ctx_r2.monthsToShow())("asRange", true);
  }
}
const _c21 = [[["label"]], [["", "prefix", ""]], [["", "textPrefix", ""]], [["input"]], [["textarea"]], [["", "textSuffix", ""]], [["", "suffix", ""]], [["", "error", ""]], [["", "hint", ""]]];
const _c22 = ["label", "[prefix]", "[textPrefix]", "input", "textarea", "[textSuffix]", "[suffix]", "[error]", "[hint]"];
const _c23 = [[["label"]], [["input"]]];
const _c24 = ["label", "input"];
const _c25 = ["option"];
const _c26 = ["input"];
const _c27 = ["*", [["", "menu", ""]]];
const _c28 = ["*", "[menu]"];
const _c29 = [[["label"]], "*"];
const _c30 = ["label", "*"];
const _c31 = ["optionsWrap"];
const _c32 = [[["label"]], [["input"]], [["textarea"]]];
const _c33 = ["label", "input", "textarea"];
const _c34 = () => ({
  closeOnButton: false,
  closeOnEsc: false
});
const _c35 = a0 => ({
  $implicit: a0
});
function SparkleSelectComponent_Conditional_16_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.inputValue(), " ");
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_0_Conditional_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_0_Conditional_1_ng_container_0_Template, 1, 0, "ng-container", 13);
  }
  if (rf & 2) {
    const selectedOption_r4 = i0.ɵɵnextContext(3).$implicit;
    i0.ɵɵnextContext(2);
    const _selOptionTemplate_r5 = i0.ɵɵreadContextLet(6);
    i0.ɵɵproperty("ngTemplateOutlet", _selOptionTemplate_r5)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c35, selectedOption_r4));
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_0_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const selectedOption_r4 = i0.ɵɵnextContext(3).$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.getLabel(selectedOption_r4), " ");
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "spk-chip", 11);
    i0.ɵɵtemplate(1, SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_0_Conditional_1_Template, 1, 4, "ng-container")(2, SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_0_Conditional_2_Template, 1, 1);
    i0.ɵɵelementStart(3, "spk-icon", 12);
    i0.ɵɵlistener("click", function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_0_Template_spk_icon_click_3_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const $index_r6 = i0.ɵɵnextContext(2).$index;
      const ctx_r1 = i0.ɵɵnextContext(2);
      return i0.ɵɵresetView(ctx_r1.removeSelectedOptionByIndex($event, $index_r6));
    });
    i0.ɵɵtext(4, "x-bold");
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext(4);
    const _selOptionTemplate_r5 = i0.ɵɵreadContextLet(6);
    i0.ɵɵclassMap(ctx_r1.selectClasses());
    i0.ɵɵadvance();
    i0.ɵɵconditional(_selOptionTemplate_r5 ? 1 : 2);
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_1_Conditional_0_Conditional_0_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_1_Conditional_0_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_1_Conditional_0_Conditional_0_ng_container_0_Template, 1, 0, "ng-container", 13);
  }
  if (rf & 2) {
    const selectedOption_r4 = i0.ɵɵnextContext(4).$implicit;
    i0.ɵɵnextContext(2);
    const _selOptionTemplate_r5 = i0.ɵɵreadContextLet(6);
    i0.ɵɵproperty("ngTemplateOutlet", _selOptionTemplate_r5)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c35, selectedOption_r4));
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_1_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const selectedOption_r4 = i0.ɵɵnextContext(4).$implicit;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", ctx_r1.getLabel(selectedOption_r4), " ");
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_1_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_1_Conditional_0_Conditional_0_Template, 1, 4, "ng-container")(1, SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_1_Conditional_0_Conditional_1_Template, 1, 1);
  }
  if (rf & 2) {
    i0.ɵɵnextContext(5);
    const _selOptionTemplate_r5 = i0.ɵɵreadContextLet(6);
    i0.ɵɵconditional(_selOptionTemplate_r5 ? 0 : 1);
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_1_Conditional_0_Template, 2, 1);
  }
  if (rf & 2) {
    i0.ɵɵnextContext(4);
    const _showSearchText_r7 = i0.ɵɵreadContextLet(9);
    i0.ɵɵconditional(!_showSearchText_r7 ? 0 : -1);
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_0_Template, 5, 3, "spk-chip", 10)(1, SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Conditional_1_Template, 1, 1);
  }
  if (rf & 2) {
    i0.ɵɵnextContext(3);
    const _asChips_r8 = i0.ɵɵreadContextLet(8);
    i0.ɵɵconditional(_asChips_r8 ? 0 : 1);
  }
}
function SparkleSelectComponent_Conditional_17_For_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSelectComponent_Conditional_17_For_1_Conditional_0_Template, 2, 1);
  }
  if (rf & 2) {
    const selectedOption_r4 = ctx.$implicit;
    i0.ɵɵconditional(selectedOption_r4 ? 0 : -1);
  }
}
function SparkleSelectComponent_Conditional_17_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵrepeaterCreate(0, SparkleSelectComponent_Conditional_17_For_1_Template, 1, 1, null, null, i0.ɵɵrepeaterTrackByIndex);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _selectedOptions_r9 = i0.ɵɵreadContextLet(4);
    i0.ɵɵrepeater(_selectedOptions_r9);
  }
}
function SparkleSelectComponent_Conditional_18_Conditional_0_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function SparkleSelectComponent_Conditional_18_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSelectComponent_Conditional_18_Conditional_0_ng_container_0_Template, 1, 0, "ng-container", 14);
  }
  if (rf & 2) {
    i0.ɵɵnextContext(2);
    const _placeholderTemplate_r10 = i0.ɵɵreadContextLet(0);
    i0.ɵɵproperty("ngTemplateOutlet", _placeholderTemplate_r10);
  }
}
function SparkleSelectComponent_Conditional_18_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    let tmp_14_0;
    const ctx_r1 = i0.ɵɵnextContext(2);
    i0.ɵɵtextInterpolate1(" ", (tmp_14_0 = ctx_r1.placeholderText()) !== null && tmp_14_0 !== undefined ? tmp_14_0 : "", " ");
  }
}
function SparkleSelectComponent_Conditional_18_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSelectComponent_Conditional_18_Conditional_0_Template, 1, 1, "ng-container")(1, SparkleSelectComponent_Conditional_18_Conditional_1_Template, 1, 1);
  }
  if (rf & 2) {
    i0.ɵɵnextContext();
    const _placeholderTemplate_r10 = i0.ɵɵreadContextLet(0);
    i0.ɵɵconditional(_placeholderTemplate_r10 ? 0 : 1);
  }
}
function SparkleSelectComponent_Conditional_21_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "caret-down");
    i0.ɵɵelementEnd();
  }
}
function SparkleSelectComponent_Conditional_22_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "spk-spinner", 7);
  }
}
function SparkleSelectComponent_Conditional_23_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "list-magnifying-glass");
    i0.ɵɵelementEnd();
  }
}
function SparkleSelectComponent_Conditional_24_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "magnifying-glass");
    i0.ɵɵelementEnd();
  }
}
function SparkleSelectComponent_Conditional_25_Template(rf, ctx) {
  if (rf & 1) {
    const _r11 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "spk-icon", 15);
    i0.ɵɵlistener("click", function SparkleSelectComponent_Conditional_25_Template_spk_icon_click_0_listener($event) {
      i0.ɵɵrestoreView(_r11);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.clear($event));
    });
    i0.ɵɵtext(1, "x-bold");
    i0.ɵɵelementEnd();
  }
}
function SparkleSelectComponent_Conditional_26_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "caret-down");
    i0.ɵɵelementEnd();
  }
}
function SparkleSelectComponent_Conditional_27_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon", 6);
    i0.ɵɵtext(1, "caret-up");
    i0.ɵɵelementEnd();
  }
}
function SparkleSelectComponent_For_31_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "spk-checkbox");
  }
  if (rf & 2) {
    const $index_r13 = i0.ɵɵnextContext().$index;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵclassMap(ctx_r1.selectClasses());
    i0.ɵɵclassProp("active", ctx_r1.isSelected($index_r13));
  }
}
function SparkleSelectComponent_For_31_Conditional_2_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function SparkleSelectComponent_For_31_Conditional_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSelectComponent_For_31_Conditional_2_ng_container_0_Template, 1, 0, "ng-container", 13);
  }
  if (rf & 2) {
    const option_r14 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵnextContext();
    const _listOptionTemplate_r15 = i0.ɵɵreadContextLet(7);
    i0.ɵɵproperty("ngTemplateOutlet", _listOptionTemplate_r15)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c35, option_r14));
  }
}
function SparkleSelectComponent_For_31_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0);
  }
  if (rf & 2) {
    const option_r14 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵtextInterpolate1(" ", ctx_r1.getLabel(option_r14), " ");
  }
}
function SparkleSelectComponent_For_31_Template(rf, ctx) {
  if (rf & 1) {
    const _r12 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "li", 16);
    i0.ɵɵlistener("click", function SparkleSelectComponent_For_31_Template_li_click_0_listener() {
      const $index_r13 = i0.ɵɵrestoreView(_r12).$index;
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.toggleOptionByIndex($index_r13));
    });
    i0.ɵɵtemplate(1, SparkleSelectComponent_For_31_Conditional_1_Template, 1, 4, "spk-checkbox", 17)(2, SparkleSelectComponent_For_31_Conditional_2_Template, 1, 4, "ng-container")(3, SparkleSelectComponent_For_31_Conditional_3_Template, 1, 1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const $index_r13 = ctx.$index;
    const ctx_r1 = i0.ɵɵnextContext();
    const _listOptionTemplate_r15 = i0.ɵɵreadContextLet(7);
    i0.ɵɵclassProp("selected", ctx_r1.isSelected($index_r13))("focused", $index_r13 === ctx_r1.focusedOptionIndex());
    i0.ɵɵadvance();
    i0.ɵɵconditional(ctx_r1.selectMultiple() ? 1 : -1);
    i0.ɵɵadvance();
    i0.ɵɵconditional(_listOptionTemplate_r15 ? 2 : 3);
  }
}
const _c36 = ["dragImageElement"];
const _c37 = [[["", "sidenav", ""]], [["", "sidenav-closed-topbar", ""]], "*"];
const _c38 = ["[sidenav]", "[sidenav-closed-topbar]", "*"];
function SparkleSidenavComponent_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "div", 1, 0);
  }
}
function SparkleSidenavComponent_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r1 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵlistener("drop", function SparkleSidenavComponent_Conditional_1_Template_div_drop_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.drop($event));
    })("dragover", function SparkleSidenavComponent_Conditional_1_Template_div_dragover_0_listener($event) {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dragOver($event));
    })("dragenter", function SparkleSidenavComponent_Conditional_1_Template_div_dragenter_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dragEnter());
    })("dragleave", function SparkleSidenavComponent_Conditional_1_Template_div_dragleave_0_listener() {
      i0.ɵɵrestoreView(_r1);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dragLeave());
    });
    i0.ɵɵelementEnd();
  }
}
function SparkleSidenavComponent_Conditional_5_Template(rf, ctx) {
  if (rf & 1) {
    const _r3 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div", 8);
    i0.ɵɵlistener("dragstart", function SparkleSidenavComponent_Conditional_5_Template_div_dragstart_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dragStart($event));
    })("dragend", function SparkleSidenavComponent_Conditional_5_Template_div_dragend_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.dragEnd($event));
    })("drag", function SparkleSidenavComponent_Conditional_5_Template_div_drag_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.drag($event));
    })("touchstart", function SparkleSidenavComponent_Conditional_5_Template_div_touchstart_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.touchStart($event));
    })("touchmove", function SparkleSidenavComponent_Conditional_5_Template_div_touchmove_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.touchMove($event));
    })("touchend", function SparkleSidenavComponent_Conditional_5_Template_div_touchend_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.touchEnd($event));
    })("touchcancel", function SparkleSidenavComponent_Conditional_5_Template_div_touchcancel_0_listener($event) {
      i0.ɵɵrestoreView(_r3);
      const ctx_r1 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r1.touchCancel($event));
    });
    i0.ɵɵelementEnd();
  }
}
const _c39 = ["templateOne"];
const _c40 = ["templateTwo"];
const _c41 = ["placeholderRef"];
function SparkleSortableComponent_For_3_Conditional_0_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function SparkleSortableComponent_For_3_Conditional_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSortableComponent_For_3_Conditional_0_ng_container_0_Template, 1, 0, "ng-container", 2);
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.templateOne())("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c35, item_r1));
  }
}
function SparkleSortableComponent_For_3_Conditional_1_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function SparkleSortableComponent_For_3_Conditional_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSortableComponent_For_3_Conditional_1_ng_container_0_Template, 1, 0, "ng-container", 2);
  }
  if (rf & 2) {
    const item_r1 = i0.ɵɵnextContext().$implicit;
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r1.templateTwo())("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c35, item_r1));
  }
}
function SparkleSortableComponent_For_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, SparkleSortableComponent_For_3_Conditional_0_Template, 1, 4, "ng-container")(1, SparkleSortableComponent_For_3_Conditional_1_Template, 1, 4, "ng-container");
  }
  if (rf & 2) {
    const $index_r3 = ctx.$index;
    i0.ɵɵconditional($index_r3 === 0 ? 0 : 1);
  }
}
const _c42 = ["thead"];
const _c43 = [[["", "table-header", ""]], "*", [["", "table-no-rows", ""]]];
const _c44 = ["[table-header]", "*", "[table-no-rows]"];
function SparkleTableComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "spk-progress-bar", 1);
  }
}
function SparkleTableComponent_Conditional_6_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵprojection(1, 2);
    i0.ɵɵelementEnd();
  }
}
const _c45 = [[["", "title", ""]], "*"];
const _c46 = ["[title]", "*"];
function SparkleToggleCardComponent_ProjectionFallback_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtext(0, "Title");
  }
}
function SparkleToggleCardComponent_Conditional_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "spk-icon");
    i0.ɵɵtext(1, "caret-down");
    i0.ɵɵelementEnd();
  }
}
const _c47 = ["tooltipRef"];
const _c48 = ["viewport"];
const _c49 = ["item"];
const iconTypes = ['bold', 'thin', 'light', 'fill']; // Ignore 'regular' for now
class SparkleIconComponent {
  #selfRef = inject(ElementRef);
  get currentClass() {
    const text = this.#selfRef.nativeElement.innerText;
    for (let index = 0; index < iconTypes.length; index++) {
      if (text.endsWith(iconTypes[index])) return iconTypes[index];
    }
    return '';
  }
  static {
    this.ɵfac = function SparkleIconComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleIconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleIconComponent,
      selectors: [["spk-icon"]],
      hostVars: 2,
      hostBindings: function SparkleIconComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.currentClass);
        }
      },
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleIconComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleIconComponent, [{
    type: Component,
    args: [{
      selector: 'spk-icon',
      imports: [],
      template: `
    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class]': 'currentClass'
      }
    }]
  }], null, null);
})();
class SparkleAlertComponent {
  constructor() {
    this._el = inject(ElementRef); // Used by alert container
    this.alertService = input(null);
    this.id = input(null);
    this.alertClasses = computed(() => this._el.nativeElement.classList.toString());
  }
  removeAlert() {
    if (this.id() && this.alertService()) {
      this.alertService()?.removeAlert(this.id());
    }
  }
  static {
    this.ɵfac = function SparkleAlertComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleAlertComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleAlertComponent,
      selectors: [["spk-alert"]],
      inputs: {
        alertService: [1, "alertService"],
        id: [1, "id"]
      },
      ngContentSelectors: _c2,
      decls: 18,
      vars: 4,
      consts: [["ref", ""], [1, "alert"], [1, "icon"], [1, "state-icon"], [1, "title"], [1, "close-icon"], [1, "content"], [1, "close-icon", 3, "click"]],
      template: function SparkleAlertComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c1);
          i0.ɵɵelementStart(0, "div", 1)(1, "div", 2, 0);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 2);
          i0.ɵɵdeclareLet(5);
          i0.ɵɵtemplate(6, SparkleAlertComponent_Conditional_6_Template, 2, 0, "spk-icon", 3)(7, SparkleAlertComponent_Conditional_7_Template, 2, 0, "spk-icon", 3)(8, SparkleAlertComponent_Conditional_8_Template, 2, 0, "spk-icon", 3)(9, SparkleAlertComponent_Conditional_9_Template, 2, 0, "spk-icon", 3)(10, SparkleAlertComponent_Conditional_10_Template, 2, 0, "spk-icon", 3)(11, SparkleAlertComponent_Conditional_11_Template, 2, 0, "spk-icon", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(12, "div", 4);
          i0.ɵɵprojection(13, 1);
          i0.ɵɵprojection(14, 2);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(15, SparkleAlertComponent_Conditional_15_Template, 2, 0, "spk-icon", 5);
          i0.ɵɵelementStart(16, "div", 6);
          i0.ɵɵprojection(17, 3);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          const ref_r3 = i0.ɵɵreference(2);
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("display", !ref_r3.children.length ? "none" : "block");
          const _alertClasses_r4 = ctx.alertClasses();
          i0.ɵɵadvance(5);
          i0.ɵɵconditional(_alertClasses_r4.includes("primary") ? 6 : _alertClasses_r4.includes("accent") ? 7 : _alertClasses_r4.includes("warn") ? 8 : _alertClasses_r4.includes("error") ? 9 : _alertClasses_r4.includes("success") ? 10 : 11);
          i0.ɵɵadvance(9);
          i0.ɵɵconditional(ctx.id() ? 15 : -1);
        }
      },
      dependencies: [SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleAlertComponent, [{
    type: Component,
    args: [{
      selector: 'spk-alert',
      imports: [SparkleIconComponent],
      template: `
    <div class="alert">
      <div #ref class="icon" [style.display]="!ref.children.length ? 'none' : 'block'">
        <ng-content select="[icon]"></ng-content>
      </div>

      <div class="icon">
        @let _alertClasses = alertClasses();

        @if (_alertClasses.includes('primary')) {
          <spk-icon class="state-icon">info</spk-icon>
        } @else if (_alertClasses.includes('accent')) {
          <spk-icon class="state-icon">info</spk-icon>
        } @else if (_alertClasses.includes('warn')) {
          <spk-icon class="state-icon">warning</spk-icon>
        } @else if (_alertClasses.includes('error')) {
          <spk-icon class="state-icon">warning-octagon</spk-icon>
        } @else if (_alertClasses.includes('success')) {
          <spk-icon class="state-icon">check-circle</spk-icon>
        } @else {
          <spk-icon class="state-icon">question</spk-icon>
        }
      </div>

      <div class="title">
        <ng-content select="[title]"></ng-content>
        <ng-content></ng-content>
      </div>

      @if (id()) {
        <spk-icon class="close-icon" (click)="removeAlert()">plus</spk-icon>
      }

      <div class="content">
        <ng-content select="[content]"></ng-content>
      </div>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleAlertContainerComponent {
  constructor() {
    this.inline = input(null);
    this.alerts = viewChild.required('alerts');
    this.scroller = viewChild.required('scroller');
    this.alertService = input.required();
    this.alertHistory = this.alertService()?.alertHistory;
    this.alertHistoryIsOpen = this.alertService()?.alertHistoryIsOpen;
    this.alertHistoryIsHidden = this.alertService()?.alertHistoryIsHidden;
    this.numberOfOpenAlerts = computed(() => {
      return this.alertHistory().filter(x => x.isOpen).length;
    });
    this.#e = effect(() => {
      this.alertHistory();
      this.alertHistoryIsOpen();
      this.#scrollToBottom();
    });
  }
  #e;
  #scrollToBottom() {
    if (this.scroller() && this.scroller().nativeElement) {
      this.scroller().nativeElement.scrollTo(0, this.scroller().nativeElement.scrollHeight);
    }
  }
  onMouseOver() {
    if (typeof this.inline === 'string') return;
    this.alertService().setHidden(false);
  }
  onMouseOut() {
    if (typeof this.inline === 'string') return;
    this.alertService().setHidden(true);
  }
  getElementHeight(i) {
    if (!this.alerts) return 0;
    const elementHeights = this.alerts().toArray().map(element => element._el.nativeElement.querySelector('.sparkle-alert-item').offsetHeight);
    if (!elementHeights) return 0;
    let totalHeight = 0;
    const elementTransformPos = elementHeights.map((height, i) => {
      totalHeight += height;
      return totalHeight - elementHeights[0];
    });
    return elementTransformPos[i];
  }
  transformY(i) {
    return `translateY(calc(-${this.getElementHeight(i - 1)}px + (-10px * ${i})))`;
  }
  transitionDelay(i, allOpen = false) {
    return allOpen ? this.alertHistory().length - 1 * 40 + 'ms' : (this.numberOfOpenAlerts() - i) * 40 + 'ms';
  }
  static {
    this.ɵfac = function SparkleAlertContainerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleAlertContainerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleAlertContainerComponent,
      selectors: [["sparkle-alert-container"]],
      viewQuery: function SparkleAlertContainerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.alerts, _c3, 5);
          i0.ɵɵviewQuerySignal(ctx.scroller, _c4, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance(2);
        }
      },
      hostBindings: function SparkleAlertContainerComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mouseover", function SparkleAlertContainerComponent_mouseover_HostBindingHandler() {
            return ctx.onMouseOver();
          })("mouseout", function SparkleAlertContainerComponent_mouseout_HostBindingHandler() {
            return ctx.onMouseOut();
          });
        }
      },
      inputs: {
        inline: [1, "inline"],
        alertService: [1, "alertService"]
      },
      decls: 8,
      vars: 2,
      consts: [["scroller", ""], ["alerts", ""], [1, "scroller"], [1, "container"], [3, "class", "id", "alertService", "transition-delay", "animate-in", "animate-out", "is-hidden"], [1, "tray", 3, "mouseover", "mouseout"], [1, "small"], [3, "id", "alertService"], ["title", ""], ["content", ""]],
      template: function SparkleAlertContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵelementStart(0, "div", 2, 0)(2, "div", 3);
          i0.ɵɵrepeaterCreate(3, SparkleAlertContainerComponent_For_4_Template, 5, 14, "spk-alert", 4, i0.ɵɵrepeaterTrackByIndex);
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(5, "div", 5);
          i0.ɵɵlistener("mouseover", function SparkleAlertContainerComponent_Template_div_mouseover_5_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onMouseOver());
          })("mouseout", function SparkleAlertContainerComponent_Template_div_mouseout_5_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onMouseOut());
          });
          i0.ɵɵelementStart(6, "spk-icon", 6);
          i0.ɵɵtext(7, "info");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵrepeater(ctx.alertHistory());
          i0.ɵɵadvance(2);
          i0.ɵɵclassProp("tray-is-hidden", !ctx.alertHistoryIsHidden());
        }
      },
      dependencies: [SparkleAlertComponent, SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleAlertContainerComponent, [{
    type: Component,
    args: [{
      selector: 'sparkle-alert-container',
      imports: [SparkleAlertComponent, SparkleIconComponent],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<div class=\"scroller\" #scroller>\n  <div class=\"container\">\n    @for (alert of alertHistory(); track $index) {\n      <spk-alert\n        #alerts\n        [class]=\"alert.type\"\n        [id]=\"alert.id\"\n        [alertService]=\"alertService()\"\n        [style.transition-delay]=\"transitionDelay($index, true)\"\n        [class.animate-in]=\"alert.animateIn\"\n        [class.animate-out]=\"alert.animateOut\"\n        [class.is-hidden]=\"alertHistoryIsHidden() && !alert.isOpen\">\n        <div title>{{ alert.title }}</div>\n\n        @if (alert.content) {\n          <div content>\n            {{ alert.content }}\n          </div>\n        }\n      </spk-alert>\n    }\n  </div>\n</div>\n<div\n  class=\"tray\"\n  [class.tray-is-hidden]=\"!alertHistoryIsHidden()\"\n  (mouseover)=\"this.onMouseOver()\"\n  (mouseout)=\"this.onMouseOut()\">\n  <spk-icon class=\"small\">info</spk-icon>\n</div>\n"
    }]
  }], null, {
    onMouseOver: [{
      type: HostListener,
      args: ['mouseover']
    }],
    onMouseOut: [{
      type: HostListener,
      args: ['mouseout']
    }]
  });
})();
class SparkleAlertModule {
  static {
    this.ɵfac = function SparkleAlertModule_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleAlertModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: SparkleAlertModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleAlertModule, [{
    type: NgModule,
    args: [{
      imports: [SparkleAlertComponent, SparkleAlertContainerComponent],
      exports: [SparkleAlertComponent, SparkleAlertContainerComponent],
      providers: []
    }]
  }], null, null);
})();
function generateUniqueId() {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
}
class SparkleAlertService {
  constructor() {
    this.alertHistory = signal([]);
    this.alertHistoryIsOpen = signal(false);
    this.alertHistoryIsHidden = signal(true);
  }
  error(message) {
    this.addAlert({
      type: 'error',
      title: message ?? 'An error occured'
    });
  }
  success(message) {
    this.addAlert({
      type: 'success',
      title: message
    });
  }
  question(message) {
    this.addAlert({
      type: 'question',
      title: message
    });
  }
  warning(message) {
    this.addAlert({
      type: 'warn',
      title: message
    });
  }
  info(message) {
    this.addAlert({
      type: 'primary',
      title: message
    });
  }
  addAlert(alert) {
    const id = generateUniqueId();
    this.alertHistory.update(history => [{
      ...alert,
      isOpen: true,
      animateIn: true,
      animateOut: false,
      id
    }, ...history]);
    setTimeout(() => {
      this.alertHistory.update(history => history.map(item => ({
        ...item,
        animateIn: item.id === id ? false : item.animateIn
      })));
    }, 40);
    setTimeout(() => {
      this.hideAlert(id);
    }, 2500);
  }
  removeAlert(id) {
    this.alertHistory.update(history => history.map(item => ({
      ...item,
      animateOut: item.id === id ? false : item.animateOut
    })));
    setTimeout(() => {
      this.alertHistory.update(history => history.filter(item => item.id !== id));
    }, 300);
  }
  hideAlert(id) {
    this.alertHistory.update(history => history.map(item => ({
      ...item,
      isOpen: item.id === id ? false : item.isOpen
    })));
  }
  setHidden(isHidden) {
    this.alertHistoryIsHidden.set(isHidden);
  }
  static {
    this.ɵfac = function SparkleAlertService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleAlertService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SparkleAlertService,
      factory: SparkleAlertService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleAlertService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
const DEFAULT_OPTIONS$1 = {
  width: undefined,
  maxWidth: undefined,
  height: undefined,
  maxHeight: undefined,
  closeOnButton: true,
  closeOnEsc: true,
  closeOnOutsideClick: true
};
class SparkleDialogComponent {
  constructor() {
    this.dialogRef = viewChild('dialogRef');
    this.isOpen = model(false);
    this.options = input();
    this.closed = output();
    this.defaultOptionMerge = computed(() => ({
      ...DEFAULT_OPTIONS$1,
      ...this.options()
    }));
    this.abortController = null;
    this.isOpenEffect = effect(() => {
      const dialogEl = this.dialogRef()?.nativeElement;
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      if (this.isOpen()) {
        dialogEl?.showModal();
        dialogEl?.addEventListener('close', () => {
          this.isOpen.set(false);
          this.closed.emit();
        }, {
          signal: this.abortController?.signal
        });
        document.addEventListener('keydown', e => {
          if (e.key === 'Escape' && !this.defaultOptionMerge().closeOnEsc) {
            e.preventDefault();
          }
          if (e.key === 'Escape' && this.defaultOptionMerge().closeOnEsc) {
            this.isOpen.set(false);
          }
        }, {
          signal: this.abortController?.signal
        });
      } else {
        this.closed.emit();
        dialogEl?.close();
      }
    });
  }
  ngOnDestroy() {
    this.abortController?.abort();
  }
  static {
    this.ɵfac = function SparkleDialogComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleDialogComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleDialogComponent,
      selectors: [["spk-dialog"]],
      viewQuery: function SparkleDialogComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.dialogRef, _c5, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        isOpen: [1, "isOpen"],
        options: [1, "options"]
      },
      outputs: {
        isOpen: "isOpenChange",
        closed: "closed"
      },
      ngContentSelectors: _c0,
      decls: 5,
      vars: 9,
      consts: [["dialogRef", ""], ["spkDialog", ""], [1, "content"], [1, "closeable-overlay"], [1, "closeable-overlay", 3, "click"]],
      template: function SparkleDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "dialog", 1, 0)(2, "div", 2);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, SparkleDialogComponent_Conditional_4_Template, 1, 0, "div", 3);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          let tmp_1_0;
          let tmp_2_0;
          let tmp_3_0;
          let tmp_4_0;
          i0.ɵɵstyleProp("width", (tmp_1_0 = ctx.defaultOptionMerge().width) !== null && tmp_1_0 !== undefined ? tmp_1_0 : "")("max-width", (tmp_2_0 = ctx.defaultOptionMerge().maxWidth) !== null && tmp_2_0 !== undefined ? tmp_2_0 : "")("max-height", (tmp_3_0 = ctx.defaultOptionMerge().maxHeight) !== null && tmp_3_0 !== undefined ? tmp_3_0 : "")("height", (tmp_4_0 = ctx.defaultOptionMerge().height) !== null && tmp_4_0 !== undefined ? tmp_4_0 : "");
          i0.ɵɵadvance(4);
          i0.ɵɵconditional(ctx.defaultOptionMerge().closeOnOutsideClick ? 4 : -1);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleDialogComponent, [{
    type: Component,
    args: [{
      selector: 'spk-dialog',
      imports: [],
      template: `
    <dialog
      spkDialog
      #dialogRef
      [style.width]="defaultOptionMerge().width ?? ''"
      [style.max-width]="defaultOptionMerge().maxWidth ?? ''"
      [style.max-height]="defaultOptionMerge().maxHeight ?? ''"
      [style.height]="defaultOptionMerge().height ?? ''">
      <div class="content">
        <ng-content />
      </div>

      @if (this.defaultOptionMerge().closeOnOutsideClick) {
        <div class="closeable-overlay" (click)="isOpen.set(false)"></div>
      }
    </dialog>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();

// interface CustomSparkleDialogComponent {
//   data?: any;
//   close?: EventEmitter<any>;
// }
class SparkleDialogService {
  constructor() {
    this.#bodyEl = document.querySelector('body');
    this.#appRef = inject(ApplicationRef);
    this.compRef = null;
    this.insertedCompRef = null;
    this.closedFieldSub = null;
    this.compClosedSub = null;
  }
  #bodyEl;
  #appRef;
  open(component, options) {
    const environmentInjector = this.#appRef.injector;
    const hostElement = this.#createEl();
    const {
      data,
      closed,
      ...rest
    } = options || {};
    if (this.compRef) {
      this.#cleanupRefs();
    }
    this.insertedCompRef = createComponent(component, {
      environmentInjector
    });
    this.compRef = createComponent(SparkleDialogComponent, {
      hostElement,
      environmentInjector,
      projectableNodes: [[this.insertedCompRef.location.nativeElement]]
    });
    const dataField = this.insertedCompRef.instance?.data;
    const closedField = this.insertedCompRef.instance?.closed;
    if (data) {
      if (isSignal(dataField)) {
        this.insertedCompRef.setInput('data', data);
      } else if (!isSignal(dataField)) {
        throw new Error('data is not an input signal on the passed component');
      }
    }
    if (closedField instanceof OutputEmitterRef) {
      this.closedFieldSub = closedField.subscribe((...args) => {
        closed?.(...args);
        this.#cleanupRefs();
      });
    }
    this.#appRef.attachView(this.insertedCompRef.hostView);
    this.#appRef.attachView(this.compRef.hostView);
    this.insertedCompRef.changeDetectorRef.detectChanges();
    this.compRef.changeDetectorRef.detectChanges();
    this.compRef.instance.isOpen.set(true);
    this.compRef.setInput('options', rest);
    this.compRef.instance.closed.subscribe(() => closeAction());
    const _self = this;
    function closeAction() {
      if (closedField && closedField instanceof OutputEmitterRef) {
        closedField.emit(false);
      } else {
        closed?.(undefined);
      }
      _self.#cleanupRefs();
    }
    return this.insertedCompRef.instance;
  }
  #createEl() {
    const wrapperEl = document.createElement('spk-dialog-ref');
    wrapperEl.id = 'spk-dialog-ref';
    if (!document.getElementById('spk-dialog-ref')) {
      this.#bodyEl.append(wrapperEl);
    }
    return document.getElementById('spk-dialog-ref');
  }
  #cleanupRefs() {
    if (this.insertedCompRef) {
      this.#appRef.detachView(this.insertedCompRef.hostView);
      this.closedFieldSub?.unsubscribe();
      this.insertedCompRef.destroy();
    }
    if (!this.compRef) return;
    this.#appRef.detachView(this.compRef.hostView);
    this.compClosedSub?.unsubscribe();
    this.compRef.destroy();
  }
  ngOnDestroy() {
    this.#cleanupRefs();
  }
  static {
    this.ɵfac = function SparkleDialogService_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleDialogService)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SparkleDialogService,
      factory: SparkleDialogService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleDialogService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], null, null);
})();
class SparkleButtonGroupComponent {
  static {
    this.ɵfac = function SparkleButtonGroupComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleButtonGroupComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleButtonGroupComponent,
      selectors: [["spk-button-group"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleButtonGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleButtonGroupComponent, [{
    type: Component,
    args: [{
      selector: 'spk-button-group',
      imports: [],
      template: `
    <ng-content></ng-content>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleButtonComponent {
  static {
    this.ɵfac = function SparkleButtonComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleButtonComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleButtonComponent,
      selectors: [["", "spk-button", ""]],
      attrs: _c6,
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleButtonComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleButtonComponent, [{
    type: Component,
    args: [{
      selector: '[spk-button]',
      imports: [],
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleCardComponent {
  static {
    this.ɵfac = function SparkleCardComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleCardComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleCardComponent,
      selectors: [["spk-card"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleCardComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleCardComponent, [{
    type: Component,
    args: [{
      selector: 'spk-card',
      imports: [],
      template: `
    <ng-content></ng-content>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleCheckboxComponent {
  #selfRef = inject(ElementRef);
  onClick() {
    if (this.#selfRef.nativeElement.querySelector('input')) {
      this.#selfRef.nativeElement.querySelector('input').focus();
    }
  }
  static {
    this.ɵfac = function SparkleCheckboxComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleCheckboxComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleCheckboxComponent,
      selectors: [["spk-checkbox"]],
      hostBindings: function SparkleCheckboxComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function SparkleCheckboxComponent_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      ngContentSelectors: _c0,
      decls: 6,
      vars: 0,
      consts: [[1, "box"], [1, "inherit", "default-indicator"], [1, "inherit", "indeterminate-indicator"]],
      template: function SparkleCheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0)(1, "spk-icon", 1);
          i0.ɵɵtext(2, "check");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "spk-icon", 2);
          i0.ɵɵtext(4, "minus");
          i0.ɵɵelementEnd()();
          i0.ɵɵprojection(5);
        }
      },
      dependencies: [SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleCheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'spk-checkbox',
      imports: [SparkleIconComponent],
      template: `
    <div class="box">
      <spk-icon class="inherit default-indicator">check</spk-icon>
      <spk-icon class="inherit indeterminate-indicator">minus</spk-icon>
    </div>

    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class SparkleChipComponent {
  static {
    this.ɵfac = function SparkleChipComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleChipComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleChipComponent,
      selectors: [["spk-chip"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleChipComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleChipComponent, [{
    type: Component,
    args: [{
      selector: 'spk-chip',
      imports: [],
      template: '<ng-content></ng-content>',
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();

// TODOS
// - Add a color picker input
// - Add alpha support
class SparkleColorPickerComponent {
  constructor() {
    this.canvasRef = viewChild.required('colorCanvas');
    this.canvasData = signal(null);
    this.showDarkColors = input(false);
    this.renderingType = input('hsl');
    this.gridSize = input(20);
    this.hue = input(0);
    this.direction = input('horizontal');
    this.selectedColor = model([255, 255, 255]);
    this.currentColor = output();
    this.centerLightness = computed(() => this.showDarkColors() ? 200 : 100);
    this.isDragging = signal(false);
    this.markerPosition = signal({
      x: '50%',
      y: '50%'
    });
    this._pos = {
      x: '0',
      y: '0'
    };
    this._markerPosition = effect(() => this._pos = this.markerPosition());
    this.selectedColorRgb = computed(() => `rgb(${this.selectedColor().join(',')})`);
    this.selectedColorHex = computed(() => this.rgbToHex(...this.selectedColor()));
    this.selectedColorHsl = computed(() => this.rgbToHsl(...this.selectedColor()));
    this.selectedColorEffect = effect(() => {
      const selectedColor = this.selectedColor();
      const hsl = this.rgbToHsl(...selectedColor);
      const hex = this.rgbToHex(...selectedColor);
      this.currentColor.emit({
        rgb: `rgb(${selectedColor.join(',')})`,
        hex: hex,
        hsl: hsl,
        hue: hsl.match(/\d+/g).map(Number)[0],
        saturation: hsl.match(/\d+/g).map(Number)[1]
      });
    });
    this.renderingTypeEffect = effect(() => {
      const currentRenderingType = this.renderingType();
      if (this.canvasData()) {
        this.drawColorPicker();
        if (currentRenderingType === 'hsl') {
          this.adjustMarkerPosition();
          setTimeout(() => this.setColorBasedOnMarkerPosition());
        } else {
          this.setColorBasedOnMarkerPosition();
        }
      }
    });
  }
  onResize() {
    this.setCanvasSize();
  }
  ngAfterViewInit() {
    this.setCanvasSize();
    this.initCanvasEvents();
  }
  adjustMarkerPosition() {
    const {
      canvas,
      centerX,
      centerY,
      radius
    } = this.canvasData();
    let {
      x,
      y
    } = this._pos;
    let markerX = parseFloat(x.replace('%', '')) / 100 * canvas.width;
    let markerY = parseFloat(y.replace('%', '')) / 100 * canvas.height;
    const distance = Math.sqrt(Math.pow(markerX - centerX, 2) + Math.pow(markerY - centerY, 2));
    if (distance > radius) {
      const angle = Math.atan2(markerY - centerY, markerX - centerX);
      markerX = centerX + radius * Math.cos(angle);
      markerY = centerY + radius * Math.sin(angle);
      x = (markerX / canvas.width * 100).toFixed(2) + '%';
      y = (markerY / canvas.height * 100).toFixed(2) + '%';
      this.markerPosition.set({
        x,
        y
      });
    }
  }
  setColorBasedOnMarkerPosition() {
    const {
      canvas,
      ctx
    } = this.canvasData();
    const {
      x,
      y
    } = this._pos;
    const mouseX = parseFloat(x.replace('%', '')) / 100 * canvas.width;
    const mouseY = parseFloat(y.replace('%', '')) / 100 * canvas.height;
    const pixelData = ctx.getImageData(Math.round(mouseX), Math.round(mouseY), 1, 1).data;
    this.selectedColor.set([pixelData[0], pixelData[1], pixelData[2]]);
  }
  initCanvasEvents() {
    const {
      canvas
    } = this.canvasData();
    canvas.addEventListener('mousedown', event => {
      this.isDragging.set(true);
      this.updateColorAndMarker(event);
    });
    document.addEventListener('mousemove', event => {
      if (this.isDragging()) {
        event.preventDefault();
        this.updateColorAndMarker(event, true);
      }
    });
    document.addEventListener('mouseup', () => this.isDragging.set(false));
    canvas.addEventListener('touchstart', _ => this.isDragging.set(true));
    document.addEventListener('touchmove', event => {
      if (this.isDragging()) {
        event.preventDefault();
        this.updateColorAndMarker(event.touches[0], true);
      }
    });
    document.addEventListener('touchend', () => this.isDragging.set(false));
    document.addEventListener('touchcancel', () => this.isDragging.set(false));
  }
  setCanvasSize() {
    const canvas = this.canvasRef()?.nativeElement;
    if (canvas) {
      const ctx = canvas.getContext('2d', {
        willReadFrequently: true,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'high'
      });
      const parentWidth = canvas.parentElement?.offsetWidth || canvas.offsetWidth;
      canvas.width = parentWidth;
      canvas.height = parentWidth;
      this.canvasData.set({
        canvas,
        ctx,
        centerX: canvas.width / 2,
        centerY: canvas.height / 2,
        radius: Math.min(canvas.width, canvas.height) / 2
      });
      this.drawColorPicker();
    }
  }
  updateColorAndMarker(event, outsideCanvas = false) {
    const {
      canvas,
      ctx
    } = this.canvasData();
    let mouseX = event instanceof MouseEvent ? event.offsetX : event.clientX;
    let mouseY = event instanceof MouseEvent ? event.offsetY : event.clientY;
    if (outsideCanvas) {
      const rect = canvas.getBoundingClientRect();
      mouseX = Math.round(event.clientX - rect.left);
      mouseY = Math.round(event.clientY - rect.top);
    }
    if (this.renderingType() === 'hsl') {
      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;
      const radius = Math.min(canvas.width, canvas.height) / 2;
      const distance = Math.sqrt(Math.pow(mouseX - centerX, 2) + Math.pow(mouseY - centerY, 2));
      if (distance > radius) {
        const angle = Math.atan2(mouseY - centerY, mouseX - centerX);
        mouseX = centerX + radius * Math.cos(angle);
        mouseY = centerY + radius * Math.sin(angle);
      }
    }
    mouseX = Math.max(0, Math.min(canvas.width - 1, Math.round(mouseX)));
    mouseY = Math.max(0, Math.min(canvas.height - 1, Math.round(mouseY)));
    const pixelData = ctx.getImageData(mouseX, mouseY, 1, 1).data;
    const [r, g, b] = pixelData;
    this.selectedColor.set([r, g, b]);
    const xPercent = (mouseX / canvas.width * 100).toFixed(2) + '%';
    const yPercent = (mouseY / canvas.height * 100).toFixed(2) + '%';
    this.markerPosition.set({
      x: xPercent,
      y: yPercent
    });
  }
  drawColorPicker() {
    switch (this.renderingType()) {
      case 'hsl':
        this.drawColorWheel();
        break;
      case 'grid':
        this.drawGrid();
        break;
      case 'hue':
        this.drawHue();
        break;
      case 'rgb':
        this.drawRgb();
        break;
      case 'saturation':
        this.drawSaturation();
        break;
    }
  }
  drawRgb() {
    const {
      canvas,
      ctx
    } = this.canvasData();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
    gradient.addColorStop(0, 'white');
    gradient.addColorStop(1, `hsl(${this.hue()}, 100%, 50%)`);
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    const gradient2 = ctx.createLinearGradient(0, 0, 0, canvas.height);
    gradient2.addColorStop(0, 'rgba(0, 0, 0, 0)');
    gradient2.addColorStop(1, 'black');
    ctx.fillStyle = gradient2;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }
  drawSaturation() {
    const {
      canvas,
      ctx
    } = this.canvasData();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    if (this.direction() === 'horizontal') {
      const gradient = ctx.createLinearGradient(0, 0, canvas.width, 0);
      gradient.addColorStop(0, `hsl(${this.hue()}, 0%, 50%)`);
      gradient.addColorStop(1, `hsl(${this.hue()}, 100%, 50%)`);
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    } else {
      const gradient = ctx.createLinearGradient(0, 0, 0, canvas.height);
      gradient.addColorStop(0, `hsl(${this.hue()}, 0%, 50%)`);
      gradient.addColorStop(1, `hsl(${this.hue()}, 100%, 50%)`);
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, canvas.width, canvas.height);
    }
  }
  drawHue() {
    const {
      canvas,
      ctx
    } = this.canvasData();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const gradient = ctx.createLinearGradient(0, 0, this.direction() === 'horizontal' ? canvas.width : 0, this.direction() === 'horizontal' ? 0 : canvas.height);
    for (let i = 0; i <= 360; i += 10) {
      gradient.addColorStop(i / 360, `hsl(${i}, 100%, 50%)`);
    }
    ctx.fillStyle = gradient;
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }
  drawColorWheel() {
    const {
      canvas,
      ctx,
      centerX,
      centerY,
      radius
    } = this.canvasData();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    for (let y = 0; y < canvas.height; y++) {
      for (let x = 0; x < canvas.width; x++) {
        const distance = Math.sqrt(Math.pow(x - centerX, 2) + Math.pow(y - centerY, 2));
        if (distance <= radius + 2) {
          const angle = Math.atan2(y - centerY, x - centerX);
          const hue = (angle + Math.PI) / (2 * Math.PI) * 360;
          ctx.fillStyle = `hsl(${hue}, 100%, ${100 - this.centerLightness() / 100 * (distance / radius) * 50}%)`;
          ctx.fillRect(x, y, 1, 1);
        }
      }
    }
  }
  drawGrid() {
    const {
      canvas,
      ctx
    } = this.canvasData();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const gridSize = this.gridSize();
    const cellSize = canvas.width / gridSize;
    const inputHue = this.hue();
    const reversedHue = (inputHue + 180) % 360;
    const maxDistance = Math.sqrt(2) * (gridSize - 1);
    for (let y = 0; y < canvas.height; y += cellSize) {
      for (let x = 0; x < canvas.width; x += cellSize) {
        const cellX = Math.floor(x / cellSize);
        const cellY = Math.floor(y / cellSize);
        const distanceTopLeft = Math.sqrt(cellX * cellX + cellY * cellY);
        const l = Math.floor(distanceTopLeft / maxDistance * 100);
        const h = cellX >= cellY ? inputHue : reversedHue;
        const distanceFromCenter = Math.abs(cellX - cellY) / (gridSize - 1);
        const s = Math.floor(distanceFromCenter * 100);
        ctx.fillStyle = `hsl(${h}, ${s}%, ${l}%)`;
        ctx.fillRect(x, y, cellSize, cellSize);
      }
    }
  }
  rgbToHex(r, g, b) {
    return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  }
  rgbToHsl(r, g, b) {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b),
      min = Math.min(r, g, b);
    let h = 0,
      s = 0,
      l = (max + min) / 2;
    if (max === min) {
      h = s = 0;
    } else {
      const d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
        case r:
          h = (g - b) / d + (g < b ? 6 : 0);
          break;
        case g:
          h = (b - r) / d + 2;
          break;
        case b:
          h = (r - g) / d + 4;
          break;
      }
      h /= 6;
    }
    return `hsl(${Math.floor(h * 360)}, ${Math.round(s * 100)}%, ${Math.round(l * 100)}%)`;
  }
  static {
    this.ɵfac = function SparkleColorPickerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleColorPickerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleColorPickerComponent,
      selectors: [["spk-color-picker"]],
      viewQuery: function SparkleColorPickerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.canvasRef, _c7, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      hostVars: 4,
      hostBindings: function SparkleColorPickerComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("resize", function SparkleColorPickerComponent_resize_HostBindingHandler($event) {
            return ctx.onResize($event);
          }, false, i0.ɵɵresolveWindow);
        }
        if (rf & 2) {
          i0.ɵɵclassMap(ctx.renderingType());
          i0.ɵɵclassProp("vertical", (ctx.renderingType() === "hue" || ctx.renderingType() === "saturation") && ctx.direction() === "vertical");
        }
      },
      inputs: {
        showDarkColors: [1, "showDarkColors"],
        renderingType: [1, "renderingType"],
        gridSize: [1, "gridSize"],
        hue: [1, "hue"],
        direction: [1, "direction"],
        selectedColor: [1, "selectedColor"]
      },
      outputs: {
        selectedColor: "selectedColorChange",
        currentColor: "currentColor"
      },
      decls: 3,
      vars: 6,
      consts: [["colorCanvas", ""], [1, "marker"]],
      template: function SparkleColorPickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "canvas", null, 0)(2, "div", 1);
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵstyleProp("left", ctx.markerPosition().x)("top", ctx.markerPosition().y)("background", ctx.selectedColorRgb());
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleColorPickerComponent, [{
    type: Component,
    args: [{
      selector: 'spk-color-picker',
      imports: [],
      template: `
    <canvas #colorCanvas></canvas>
    <div
      class="marker"
      [style.left]="markerPosition().x"
      [style.top]="markerPosition().y"
      [style.background]="selectedColorRgb()"></div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class]': 'renderingType()',
        '[class.vertical]': '(renderingType() === "hue" || renderingType() === "saturation") && direction() === "vertical"'
      }
    }]
  }], null, {
    onResize: [{
      type: HostListener,
      args: ['window:resize', ['$event']]
    }]
  });
})();
const SCROLLABLE_STYLES = ['scroll', 'auto'];
const DEFAULT_OPTIONS = {
  width: undefined,
  height: undefined,
  closeOnButton: true,
  closeOnEsc: true
};
class SparklePopoverComponent {
  constructor() {
    this.#cdr = inject(ChangeDetectorRef);
    this.#BASE_SPACE = 4;
    this.SUPPORTS_ANCHOR = typeof CSS !== 'undefined' && CSS.supports('position-anchor', '--abc') && CSS.supports('anchor-name', '--abc');
    this.above = input(false);
    this.right = input(false);
    this.markForCheck = input(null);
    this._above = signal(this.above());
    this._right = signal(this.right());
    this.disableOpenByClick = input(false);
    this.isOpen = model(false);
    this.options = input();
    this.closed = output();
    this.defaultOptionMerge = computed(() => ({
      ...DEFAULT_OPTIONS,
      ...this.options()
    }));
    this.triggerRef = viewChild.required('triggerRef');
    this.popoverRef = viewChild.required('popoverRef');
    this.id = signal('--' + generateUniqueId());
    this.menuStyle = signal(null);
    this.openAbort = null;
    this.openEffect = effect(() => {
      const popoverEl = this.popoverRef()?.nativeElement;
      const open = this.isOpen();
      if (open) {
        if (this.openAbort) {
          this.openAbort.abort();
        }
        this.openAbort = new AbortController();
        const abortOptions = {
          signal: this.openAbort?.signal
        };
        popoverEl?.showPopover();
        document.addEventListener('keydown', e => {
          if (e.key === 'Escape' && !this.defaultOptionMerge().closeOnEsc) {
            e.preventDefault();
          }
          if (e.key === 'Escape' && this.defaultOptionMerge().closeOnEsc) {
            this.isOpen.set(false);
          }
        }, abortOptions);
        setTimeout(() => {
          const scrollableParent = this.#findScrollableParent(this.popoverRef()?.nativeElement);
          scrollableParent.addEventListener('scroll', () => this.#calculateMenuPosition(), abortOptions);
          window.addEventListener('resize', () => this.#calculateMenuPosition(), abortOptions);
          this.#calculateMenuPosition();
        });
      } else {
        popoverEl.hidePopover();
        this.openAbort?.abort();
        this.closed.emit();
      }
    });
    this.markForCheckEffect = effect(() => {
      if (this.markForCheck()) {
        this.#cdr.markForCheck();
      }
    });
  }
  #cdr;
  #BASE_SPACE;
  toggleIsOpen(event) {
    event.preventDefault();
    event.stopPropagation();
    if (!this.disableOpenByClick()) {
      this.isOpen.set(!this.isOpen());
    }
  }
  eventClose($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.isOpen.set(false);
  }
  #findScrollableParent(element) {
    let parent = element.parentElement;
    while (parent) {
      if (SCROLLABLE_STYLES.indexOf(window.getComputedStyle(parent).overflowY) > -1 && parent.scrollHeight > parent.clientHeight) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return document.documentElement;
  }
  #calculateMenuPosition() {
    const triggerRect = this.triggerRef()?.nativeElement.getBoundingClientRect();
    const menuRect = this.popoverRef()?.nativeElement.getBoundingClientRect();
    const actionLeftInViewport = triggerRect.left;
    const actionBottomInViewport = triggerRect.bottom;
    let newLeft = actionLeftInViewport;
    let newTop = actionBottomInViewport + this.#BASE_SPACE;
    const outOfBoundsRight = newLeft + menuRect.width > window.innerWidth;
    const outOfBoundsBottom = newTop + menuRect.height > window.innerHeight;
    if (this.SUPPORTS_ANCHOR) {
      this._above.set(outOfBoundsBottom);
      this._right.set(outOfBoundsRight);
    } else {
      // Default position below and left aligned
      newLeft = actionLeftInViewport;
      newTop = actionBottomInViewport + this.#BASE_SPACE;
      if (outOfBoundsBottom) {
        // If overflows bottom, try positioning above
        const _newTop = triggerRect.top - menuRect.height - this.#BASE_SPACE;
        // Calculate outOfBoundsTop here
        const outOfBoundsTop = _newTop < 0;
        if (!outOfBoundsTop) newTop = _newTop;
      }
      if (outOfBoundsRight) {
        // If overflows right, position left
        newLeft = triggerRect.right - menuRect.width;
        // Ensure it doesn't go off-screen to the left
        if (newLeft < 0) {
          newLeft = 0;
        }
      }
      const style = {
        left: newLeft + 'px',
        top: newTop + 'px'
      };
      this.menuStyle.set(style);
    }
  }
  static {
    this.ɵfac = function SparklePopoverComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparklePopoverComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparklePopoverComponent,
      selectors: [["spk-popover"]],
      viewQuery: function SparklePopoverComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.triggerRef, _c8, 5);
          i0.ɵɵviewQuerySignal(ctx.popoverRef, _c9, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance(2);
        }
      },
      hostVars: 4,
      hostBindings: function SparklePopoverComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("above", ctx._above())("right", ctx._right());
        }
      },
      inputs: {
        above: [1, "above"],
        right: [1, "right"],
        markForCheck: [1, "markForCheck"],
        disableOpenByClick: [1, "disableOpenByClick"],
        isOpen: [1, "isOpen"],
        options: [1, "options"]
      },
      outputs: {
        isOpen: "isOpenChange",
        closed: "closed"
      },
      ngContentSelectors: _c11,
      decls: 10,
      vars: 6,
      consts: [["triggerRef", ""], ["popoverRef", ""], [1, "trigger", 3, "click"], [1, "trigger-wrapper"], ["spk-button", "", 1, "outlined"], ["popover", "", 1, "popover"], [1, "overlay", 3, "click"]],
      template: function SparklePopoverComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c10);
          i0.ɵɵelementStart(0, "div", 2, 0);
          i0.ɵɵlistener("click", function SparklePopoverComponent_Template_div_click_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.toggleIsOpen($event));
          });
          i0.ɵɵelementStart(2, "div", 3);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "button", 4);
          i0.ɵɵtext(5, "Open popover");
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(6, "div", 5, 1)(8, "div", 6);
          i0.ɵɵlistener("click", function SparklePopoverComponent_Template_div_click_8_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.isOpen() && ctx.eventClose($event));
          });
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(9, 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("anchor-name", ctx.id());
          i0.ɵɵadvance(6);
          i0.ɵɵstyleMap(ctx.menuStyle());
          i0.ɵɵstyleProp("position-anchor", ctx.id());
        }
      },
      dependencies: [SparkleButtonComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparklePopoverComponent, [{
    type: Component,
    args: [{
      selector: 'spk-popover',
      imports: [SparkleButtonComponent],
      template: `
    <div class="trigger" #triggerRef [style.anchor-name]="id()" (click)="toggleIsOpen($event)">
      <div class="trigger-wrapper">
        <ng-content select="[trigger]" />
      </div>

      <button spk-button class="outlined">Open popover</button>
    </div>

    <div popover #popoverRef class="popover" [style.position-anchor]="id()" [style]="menuStyle()">
      <div class="overlay" (click)="isOpen() && eventClose($event)"></div>
      <ng-content />
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.above]': '_above()',
        '[class.right]': '_right()'
      }
    }]
  }], null, null);
})();
class SparkleFormFieldPopoverComponent {
  constructor() {
    this.#selfRef = inject(ElementRef);
    this.isOpen = model(false);
    this.closed = output();
  }
  #selfRef;
  onClick() {
    if (this.#selfRef.nativeElement.querySelector('input')) {
      this.#selfRef.nativeElement.querySelector('input').focus();
    }
    if (this.#selfRef.nativeElement.querySelector('textarea')) {
      this.#selfRef.nativeElement.querySelector('textarea').focus();
    }
  }
  close() {
    this.closed.emit();
  }
  ngOnInit() {
    const supportFieldSizing = typeof CSS !== 'undefined' && CSS.supports('field-sizing', 'content');
    const text = this.#selfRef.nativeElement.querySelector('textarea');
    if (!supportFieldSizing && text !== null) {
      const text = this.#selfRef.nativeElement.querySelector('textarea');
      function resize() {
        text.style.height = 'auto';
        text.style.height = text.scrollHeight + 'px';
      }
      /* 0-timeout to get the already changed text */
      function delayedResize() {
        window.setTimeout(resize, 0);
      }
      text.addEventListener('change', resize);
      text.addEventListener('cut', delayedResize);
      text.addEventListener('paste', delayedResize);
      text.addEventListener('drop', delayedResize);
      text.addEventListener('keydown', delayedResize);
      text.focus();
      text.select();
      resize();
    }
  }
  static {
    this.ɵfac = function SparkleFormFieldPopoverComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleFormFieldPopoverComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleFormFieldPopoverComponent,
      selectors: [["spk-form-field-popover"]],
      hostBindings: function SparkleFormFieldPopoverComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function SparkleFormFieldPopoverComponent_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      inputs: {
        isOpen: [1, "isOpen"]
      },
      outputs: {
        isOpen: "isOpenChange",
        closed: "closed"
      },
      ngContentSelectors: _c13,
      decls: 18,
      vars: 4,
      consts: [[1, "input-wrap"], [1, "prefix"], [1, "prefix-space"], [3, "isOpenChange", "closed", "isOpen", "disableOpenByClick", "options"], [1, "suffix-space"], [1, "helpers"], [1, "error"], [1, "hint"]],
      template: function SparkleFormFieldPopoverComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c12);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "div", 0)(2, "div", 1);
          i0.ɵɵprojection(3, 1);
          i0.ɵɵprojection(4, 2);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(5, "div", 2);
          i0.ɵɵelementStart(6, "spk-popover", 3);
          i0.ɵɵtwoWayListener("isOpenChange", function SparkleFormFieldPopoverComponent_Template_spk_popover_isOpenChange_6_listener($event) {
            i0.ɵɵtwoWayBindingSet(ctx.isOpen, $event) || (ctx.isOpen = $event);
            return $event;
          });
          i0.ɵɵlistener("closed", function SparkleFormFieldPopoverComponent_Template_spk_popover_closed_6_listener() {
            return ctx.close();
          });
          i0.ɵɵprojection(7, 3, ["trigger", ""]);
          i0.ɵɵprojection(8, 4);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(9, 5);
          i0.ɵɵprojection(10, 6);
          i0.ɵɵelement(11, "div", 4);
          i0.ɵɵprojection(12, 7);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(13, "div", 5)(14, "div", 6);
          i0.ɵɵprojection(15, 8);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(16, "div", 7);
          i0.ɵɵprojection(17, 9);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(6);
          i0.ɵɵtwoWayProperty("isOpen", ctx.isOpen);
          i0.ɵɵproperty("disableOpenByClick", true)("options", i0.ɵɵpureFunction0(3, _c14));
        }
      },
      dependencies: [SparklePopoverComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleFormFieldPopoverComponent, [{
    type: Component,
    args: [{
      selector: 'spk-form-field-popover',
      imports: [SparklePopoverComponent],
      template: `
    <ng-content select="label"></ng-content>

    <div class="input-wrap">
      <div class="prefix">
        <ng-content select="[prefix]"></ng-content>
        <ng-content select="[textPrefix]"></ng-content>
      </div>

      <div class="prefix-space"></div>

      <spk-popover
        [(isOpen)]="isOpen"
        [disableOpenByClick]="true"
        (closed)="close()"
        [options]="{
          closeOnButton: false,
          closeOnEsc: true,
        }">
        <ng-content trigger select="input"></ng-content>

        <ng-content select="[popoverContent]"></ng-content>
      </spk-popover>

      <ng-content select="textarea"></ng-content>

      <ng-content select="[textSuffix]"></ng-content>
      <div class="suffix-space"></div>
      <ng-content select="[suffix]"></ng-content>
    </div>

    <div class="helpers">
      <div class="error">
        <ng-content select="[error]"></ng-content>
      </div>

      <div class="hint">
        <ng-content select="[hint]"></ng-content>
      </div>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class SparkleDatepickerComponent {
  constructor() {
    this.#INIT_DATE = this.#getUTCDate(new Date());
    this.date = model(null);
    this.endDate = model(null);
    this.asRange = input(false);
    this.monthsToShow = input(1);
    this.startOfWeek = input(1);
    this.weekdayLabels = input(['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa']);
    this.daysRef = viewChild('daysRef');
    this.currentDate = signal(this.date() ?? this.#INIT_DATE);
    this.monthOffsets = computed(() => {
      return Array.from({
        length: this.monthsToShow()
      }, (_, i) => i);
    });
    this.selectedDateStylePosition = signal(null);
    this.weekdays = computed(() => {
      const startOfWeek = this.startOfWeek();
      const weekdayLabels = this.weekdayLabels();
      return weekdayLabels.slice(startOfWeek).concat(weekdayLabels.slice(0, startOfWeek));
    });
    this.#newDateEffect = effect(() => {
      if (this.monthsToShow() > 1) return;
      this.#setDateAsCurrent();
    });
  }
  #INIT_DATE;
  getLastVisibleMonth() {
    const lastMonthOffset = this.monthsToShow() - 1;
    return this.getOffsetDate(lastMonthOffset);
  }
  getOffsetDate(monthOffset) {
    const date = new Date(this.currentDate());
    date.setMonth(date.getMonth() + monthOffset);
    return date;
  }
  getMonthDates(monthOffset) {
    const offsetDate = this.getOffsetDate(monthOffset);
    return this.#generateMonthDates(offsetDate, this.startOfWeek());
  }
  #newDateEffect;
  ngOnInit() {
    if (this.monthsToShow() === 1) return;
    this.#setDateAsCurrent();
  }
  #setDateAsCurrent() {
    const newDate = this.date();
    if (newDate) this.currentDate.set(newDate);
    this.#findSelectedAndCalc();
  }
  #findSelectedAndCalc() {
    setTimeout(() => {
      const selectedElement = this.daysRef()?.nativeElement.querySelector('.sel');
      if (!selectedElement) {
        return this.selectedDateStylePosition.update(x => x ? {
          ...x,
          opacity: '0'
        } : null);
      }
      this.setSelectedDateStylePosition(selectedElement);
    });
  }
  #generateMonthDates(date, startOfWeek) {
    const year = date.getUTCFullYear();
    const month = date.getUTCMonth();
    const firstDay = new Date(Date.UTC(year, month)).getUTCDay();
    const daysInMonth = 32 - new Date(Date.UTC(year, month, 32)).getUTCDate();
    const dates = [];
    let offset = firstDay - startOfWeek;
    if (offset < 0) {
      offset += 7;
    }
    const lastDayOfPrevMonth = new Date(Date.UTC(year, month, 0)).getUTCDate();
    for (let i = offset - 1; i >= 0; i--) {
      const prevMonthDate = new Date(Date.UTC(year, month - 1, lastDayOfPrevMonth - i));
      dates.push(prevMonthDate);
    }
    for (let i = 1; i <= daysInMonth; i++) {
      dates.push(new Date(Date.UTC(year, month, i)));
    }
    let nextMonthDay = 1;
    while (dates.length % 7 !== 0) {
      dates.push(new Date(Date.UTC(year, month + 1, nextMonthDay++)));
    }
    return dates;
  }
  nextMonth() {
    this.currentDate.update(currentDate => {
      const newDate = new Date(currentDate);
      newDate.setMonth(currentDate.getMonth() + 1);
      return newDate;
    });
    this.#findSelectedAndCalc();
  }
  previousMonth() {
    this.currentDate.update(currentDate => {
      const newDate = new Date(currentDate);
      newDate.setMonth(currentDate.getMonth() - 1);
      return newDate;
    });
    this.#findSelectedAndCalc();
  }
  setDate(newDate, selectedElement) {
    const createDateWithExistingTime = (newDate, existingDate) => {
      const hours = existingDate?.getUTCHours() ?? 0;
      const minutes = existingDate?.getUTCMinutes() ?? 0;
      const seconds = existingDate?.getUTCSeconds() ?? 0;
      const milliseconds = existingDate?.getUTCMilliseconds() ?? 0;
      return this.#getUTCDate(new Date(Date.UTC(newDate.getUTCFullYear(), newDate.getUTCMonth(), newDate.getUTCDate(), hours, minutes, seconds, milliseconds)));
    };
    if (!this.asRange()) {
      this.date.set(createDateWithExistingTime(newDate, this.date()));
      this.endDate.set(null);
    } else {
      const startDate = this.date();
      const endDate = this.endDate();
      const utcDate = createDateWithExistingTime(newDate, null);
      if (!startDate) {
        this.date.set(utcDate);
      } else if (!endDate) {
        if (utcDate < startDate) {
          this.date.set(utcDate);
          this.endDate.set(null);
        } else {
          this.endDate.set(utcDate);
        }
      } else {
        this.date.set(utcDate);
        this.endDate.set(null);
      }
    }
    if (this.asRange()) return;
    this.setSelectedDateStylePosition(selectedElement);
  }
  isDateSelected(date) {
    const startDate = this.date();
    const endDate = this.endDate();
    if (startDate === null) return null;
    const startOfDay = date => {
      return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0, 0, 0));
    };
    const endOfDay = date => {
      return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 23, 59, 59, 999));
    };
    const currentDate = startOfDay(date);
    const rangeStart = startOfDay(startDate);
    const rangeEnd = endDate ? endOfDay(endDate) : null;
    let classes = [];
    if (this.asRange()) {
      if (rangeEnd === null) {
        if (currentDate.getTime() === rangeStart.getTime()) {
          classes.push('sel first last');
        }
      } else {
        if (currentDate.getTime() === rangeStart.getTime()) {
          classes.push('first');
        }
        if (currentDate.getTime() === startOfDay(rangeEnd).getTime()) {
          classes.push('last');
        }
        if (currentDate >= rangeStart && currentDate <= rangeEnd) {
          classes.push('sel');
          const dayOfWeek = currentDate.getUTCDay();
          const startOfWeek = this.startOfWeek();
          if (dayOfWeek === startOfWeek) {
            classes.push('week-start');
          }
          const endOfWeek = (startOfWeek + 6) % 7;
          if (dayOfWeek === endOfWeek) {
            classes.push('week-end');
          }
        }
        const nextDate = new Date(currentDate);
        nextDate.setUTCDate(currentDate.getUTCDate() + 1);
        const prevDate = new Date(currentDate);
        prevDate.setUTCDate(currentDate.getUTCDate() - 1);
        const isFirstOfMonth = currentDate.getUTCDate() === 1;
        const isLastOfMonth = nextDate.getUTCMonth() !== currentDate.getUTCMonth();
        if (isFirstOfMonth) {
          classes.push('month-start');
        }
        if (isLastOfMonth) {
          classes.push('month-end');
        }
      }
    } else {
      if (currentDate.getTime() === rangeStart.getTime()) {
        classes.push('sel');
      }
    }
    return classes.join(' ') || null;
  }
  setSelectedDateStylePosition(selectedElement) {
    this.selectedDateStylePosition.set({
      transform: `translate(${selectedElement.offsetLeft}px, ${selectedElement.offsetTop}px)`,
      opacity: '1'
    });
  }
  getMonthName(date) {
    return date.toLocaleString('default', {
      month: 'long'
    });
  }
  getFullYear(date) {
    return date.getFullYear();
  }
  // Rest of the component methods remain the same, but update isCurrentMonth:
  isCurrentMonth(date, monthOffset) {
    const offsetDate = this.getOffsetDate(monthOffset);
    return date.getMonth() === offsetDate.getMonth();
  }
  #getUTCDate(date) {
    const offsetMinutes = date.getTimezoneOffset();
    const timeDiffMillis = offsetMinutes * 60 * 1000;
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()) + timeDiffMillis);
  }
  static {
    this.ɵfac = function SparkleDatepickerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleDatepickerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleDatepickerComponent,
      selectors: [["spk-datepicker"]],
      viewQuery: function SparkleDatepickerComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.daysRef, _c15, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      hostVars: 4,
      hostBindings: function SparkleDatepickerComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassMap("columns-" + ctx.monthsToShow());
          i0.ɵɵclassProp("as-range", ctx.asRange());
        }
      },
      inputs: {
        date: [1, "date"],
        endDate: [1, "endDate"],
        asRange: [1, "asRange"],
        monthsToShow: [1, "monthsToShow"],
        startOfWeek: [1, "startOfWeek"],
        weekdayLabels: [1, "weekdayLabels"]
      },
      outputs: {
        date: "dateChange",
        endDate: "endDateChange"
      },
      decls: 14,
      vars: 3,
      consts: [["daysRef", ""], ["elementRef", ""], [3, "click"], [1, "title"], [1, "months-container"], [1, "month"], [1, "weekdays"], [1, "days"], [3, "out-of-scope", "class"], [1, "sel-el"]],
      template: function SparkleDatepickerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "header")(1, "button", 2);
          i0.ɵɵlistener("click", function SparkleDatepickerComponent_Template_button_click_1_listener() {
            return ctx.previousMonth();
          });
          i0.ɵɵelementStart(2, "spk-icon");
          i0.ɵɵtext(3, "caret-left");
          i0.ɵɵelementEnd()();
          i0.ɵɵelementStart(4, "div", 3);
          i0.ɵɵtext(5);
          i0.ɵɵtemplate(6, SparkleDatepickerComponent_Conditional_6_Template, 1, 1);
          i0.ɵɵtext(7);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(8, "button", 2);
          i0.ɵɵlistener("click", function SparkleDatepickerComponent_Template_button_click_8_listener() {
            return ctx.nextMonth();
          });
          i0.ɵɵelementStart(9, "spk-icon");
          i0.ɵɵtext(10, "caret-right");
          i0.ɵɵelementEnd()()();
          i0.ɵɵelementStart(11, "section", 4);
          i0.ɵɵrepeaterCreate(12, SparkleDatepickerComponent_For_13_Template, 9, 1, "div", 5, i0.ɵɵrepeaterTrackByIdentity);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(5);
          i0.ɵɵtextInterpolate1(" ", ctx.getMonthName(ctx.currentDate()), " ");
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.monthsToShow() > 1 ? 6 : -1);
          i0.ɵɵadvance();
          i0.ɵɵtextInterpolate1(" ", ctx.getFullYear(ctx.currentDate()), " ");
          i0.ɵɵadvance(5);
          i0.ɵɵrepeater(ctx.monthOffsets());
        }
      },
      dependencies: [SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleDatepickerComponent, [{
    type: Component,
    args: [{
      selector: 'spk-datepicker',
      imports: [SparkleIconComponent],
      template: `
    <header>
      <button (click)="previousMonth()"><spk-icon>caret-left</spk-icon></button>
      <div class="title">
        {{ getMonthName(currentDate()!) }}
        @if (monthsToShow() > 1) {
          - {{ getMonthName(getLastVisibleMonth()) }}
        }
        {{ getFullYear(currentDate()!) }}
      </div>
      <button (click)="nextMonth()"><spk-icon>caret-right</spk-icon></button>
    </header>

    <section class="months-container">
      @for (monthOffset of monthOffsets(); track monthOffset) {
        <div class="month">
          <nav class="weekdays">
            @for (day of weekdays(); track $index) {
              <div>{{ day }}</div>
            }
          </nav>

          <div class="days" #daysRef>
            @for (calDate of getMonthDates(monthOffset); track $index) {
              <div
                #elementRef
                [class.out-of-scope]="!isCurrentMonth(calDate, monthOffset)"
                [class]="isDateSelected(calDate)"
                (click)="setDate(calDate, elementRef)">
                {{ calDate.getDate() }}
              </div>
            }

            @if (!asRange()) {
              <article class="days">
                <div class="sel-el" [style]="selectedDateStylePosition()"></div>
              </article>
            }
          </div>
        </div>
      }
    </section>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.as-range]': 'asRange()',
        '[class]': '"columns-" + monthsToShow()'
      }
    }]
  }], null, null);
})();
class SparkleDatepickerInputComponent {
  constructor() {
    this.#INIT_DATE = this.#getUTCDate(new Date());
    this.#datePipe = inject(DatePipe);
    this.#elementRef = inject(ElementRef);
    this.#inputRef = signal(null);
    this.#triggerInput = signal(false);
    this.inputWrapRef = viewChild.required('inputWrap');
    this.masking = input('mediumDate');
    this.closed = output();
    this._maskedDate = computed(() => {
      const date = this.internalDate();
      const mask = this.masking();
      console.log(date, mask);
      if (!mask) return date;
      if (!date) return null;
      return this.#datePipe.transform(date, mask);
    });
    this.internalDate = signal(this.#INIT_DATE);
    this.isOpen = model(false);
    this.styleClasses = signal(null);
    this.#styleObserver = typeof MutationObserver !== 'undefined' && new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
          const classString = this.#elementRef.nativeElement.classList.value;
          let classObj = classString.split(' ').reduce((acc, className) => {
            acc[className] = true;
            return acc;
          }, {});
          this.styleClasses.set(classObj);
        }
      });
    });
    this.#inputObserver = typeof MutationObserver !== 'undefined' && new MutationObserver(mutations => {
      for (var mutation of mutations) {
        if (mutation.type == 'childList' && mutation.target.classList.contains('input')) {
          this.#triggerInput.set(!this.#triggerInput());
        }
      }
    });
    this.#inputRefEffect = effect(() => {
      this.#triggerInput();
      const input = this.inputWrapRef()?.nativeElement.querySelector('input');
      if (!input) return;
      this.#createCustomInputEventListener(input);
      input.addEventListener('inputValueChanged', event => {
        this.internalDate.set(event.detail.value ? this.#getUTCDate(new Date(event.detail.value)) : null);
      });
      input.addEventListener('focus', () => {
        this.isOpen.set(true);
        input.blur();
      });
      this.#inputRef.set(input);
      input.autocomplete = 'off';
      if (typeof input.value === 'string') {
        this.internalDate.set(input.value ? this.#getUTCDate(new Date(input.value)) : null);
      }
    });
  }
  #INIT_DATE;
  #datePipe;
  #elementRef;
  #inputRef;
  #triggerInput;
  #styleObserver;
  #inputObserver;
  onDateChange(date) {
    this.internalDate.set(date);
    const input = this.#inputRef();
    if (input) {
      input.value = date ? date.toUTCString() : '';
    }
  }
  open($event) {
    $event.stopPropagation();
    this.isOpen.set(true);
  }
  close() {
    this.closed.emit(this.internalDate());
  }
  ngOnInit() {
    this.styleClasses.set(this.#elementRef.nativeElement.classList.value);
    if (typeof MutationObserver !== 'undefined') {
      this.#styleObserver.observe(this.#elementRef.nativeElement, {
        attributes: true
      });
      this.#inputObserver.observe(this.inputWrapRef().nativeElement, {
        attributes: true,
        childList: true
      });
    }
  }
  #inputRefEffect;
  #createCustomInputEventListener(input) {
    Object.defineProperty(input, 'value', {
      configurable: true,
      get() {
        const descriptor = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
        return descriptor.get.call(this);
      },
      set(newVal) {
        const descriptor = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
        descriptor.set.call(this, newVal);
        const inputEvent = new CustomEvent('inputValueChanged', {
          bubbles: true,
          cancelable: true,
          detail: {
            value: newVal
          }
        });
        this.dispatchEvent(inputEvent);
        return newVal;
      }
    });
    return input;
  }
  ngOnDestroy() {
    this.#styleObserver && this.#styleObserver.disconnect();
    this.#inputObserver && this.#inputObserver.disconnect();
  }
  #getUTCDate(date) {
    const offsetMinutes = date.getTimezoneOffset();
    const timeDiffMillis = offsetMinutes * 60 * 1000;
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds(), date.getMilliseconds()) + timeDiffMillis);
  }
  static {
    this.ɵfac = function SparkleDatepickerInputComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleDatepickerInputComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleDatepickerInputComponent,
      selectors: [["spk-datepicker-input"]],
      viewQuery: function SparkleDatepickerInputComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.inputWrapRef, _c16, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        masking: [1, "masking"],
        isOpen: [1, "isOpen"]
      },
      outputs: {
        closed: "closed",
        isOpen: "isOpenChange"
      },
      features: [i0.ɵɵProvidersFeature([DatePipe])],
      ngContentSelectors: _c18,
      decls: 16,
      vars: 3,
      consts: [["inputWrap", ""], ["defaultIndicator", ""], [3, "click", "closed", "isOpenChange", "isOpen"], ["ngProjectAs", "input", 5, ["input"], 1, "input"], [1, "masked-value"], ["suffix", "", 1, "default-indicator"], ["popoverContent", ""], [3, "date", "class"], [1, "masked-value", 3, "click"], [3, "dateChange", "date"]],
      template: function SparkleDatepickerInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c17);
          i0.ɵɵelementStart(0, "spk-form-field-popover", 2);
          i0.ɵɵlistener("click", function SparkleDatepickerInputComponent_Template_spk_form_field_popover_click_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.open($event));
          })("closed", function SparkleDatepickerInputComponent_Template_spk_form_field_popover_closed_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.close());
          });
          i0.ɵɵtwoWayListener("isOpenChange", function SparkleDatepickerInputComponent_Template_spk_form_field_popover_isOpenChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            i0.ɵɵtwoWayBindingSet(ctx.isOpen, $event) || (ctx.isOpen = $event);
            return i0.ɵɵresetView($event);
          });
          i0.ɵɵprojection(1, 0, ["ngProjectAs", "label", 5, ["label"]]);
          i0.ɵɵprojection(2, 1, ["ngProjectAs", "[prefix]", 5, ["", "prefix", ""]]);
          i0.ɵɵprojection(3, 2, ["ngProjectAs", "[textPrefix]", 5, ["", "textPrefix", ""]]);
          i0.ɵɵelementStart(4, "div", 3, 0);
          i0.ɵɵtemplate(6, SparkleDatepickerInputComponent_Conditional_6_Template, 2, 1, "div", 4);
          i0.ɵɵprojection(7, 3);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(8, 4, ["ngProjectAs", "[textSuffix]", 5, ["", "textSuffix", ""]]);
          i0.ɵɵprojection(9, 5, ["ngProjectAs", "[suffix]", 5, ["", "suffix", ""]]);
          i0.ɵɵelementStart(10, "spk-icon", 5);
          i0.ɵɵtext(11, "calendar");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(12, "div", 6);
          i0.ɵɵtemplate(13, SparkleDatepickerInputComponent_Conditional_13_Template, 1, 3, "spk-datepicker", 7);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(14, SparkleDatepickerInputComponent_ng_template_14_Template, 0, 0, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor);
        }
        if (rf & 2) {
          i0.ɵɵtwoWayProperty("isOpen", ctx.isOpen);
          i0.ɵɵadvance(6);
          i0.ɵɵconditional(ctx.masking() ? 6 : -1);
          i0.ɵɵadvance(7);
          i0.ɵɵconditional(ctx.isOpen() ? 13 : -1);
        }
      },
      dependencies: [SparkleDatepickerComponent, SparkleFormFieldPopoverComponent, SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleDatepickerInputComponent, [{
    type: Component,
    args: [{
      selector: 'spk-datepicker-input',
      imports: [SparkleDatepickerComponent, SparkleFormFieldPopoverComponent, SparkleIconComponent],
      providers: [DatePipe],
      template: `
    <spk-form-field-popover (click)="open($event)" (closed)="close()" [(isOpen)]="isOpen">
      <ng-content select="label" ngProjectAs="label" />

      <ng-content select="[prefix]" ngProjectAs="[prefix]" />
      <ng-content select="[textPrefix]" ngProjectAs="[textPrefix]" />

      <div class="input" ngProjectAs="input" #inputWrap>
        @if (this.masking()) {
          <div class="masked-value" (click)="open($event)">
            {{ _maskedDate() }}
          </div>
        }
        <ng-content select="input" />
      </div>

      <ng-content select="[textSuffix]" ngProjectAs="[textSuffix]" />
      <ng-content select="[suffix]" ngProjectAs="[suffix]" />
      <spk-icon class="default-indicator" suffix>calendar</spk-icon>

      <div popoverContent>
        @if (this.isOpen()) {
          <spk-datepicker [date]="internalDate()" (dateChange)="onDateChange($event)" [class]="styleClasses()" />
        }
      </div>
    </spk-form-field-popover>

    <ng-template #defaultIndicator></ng-template>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleDateRangeInputComponent {
  #selfRef;
  #datePipe;
  get classes() {
    return `${this.#selfRef.nativeElement.classList.value}`;
  }
  constructor() {
    this.#selfRef = inject(ElementRef);
    this.startDateInputs = contentChildren('startDate');
    this.endDateInputs = contentChildren('endDate');
    this.#datePipe = inject(DatePipe);
    this.monthsToShow = input(1);
    this.masking = input('mediumDate');
    this.closed = output();
    this.startDate = signal(null);
    this.endDate = signal(null);
    this.isOpen = model(false);
    this._maskedStartDate = computed(() => {
      const date = this.startDate();
      const mask = this.masking();
      if (!mask || !date) return null;
      return this.#datePipe.transform(date, mask);
    });
    this._maskedEndDate = computed(() => {
      const date = this.endDate();
      const mask = this.masking();
      if (!mask || !date) return null;
      return this.#datePipe.transform(date, mask);
    });
    effect(() => {
      // Setup start date inputs
      this.startDateInputs().forEach(input => {
        this.setupInput(input, true);
      });
      // Setup end date inputs
      this.endDateInputs().forEach(input => {
        this.setupInput(input, false);
      });
    });
  }
  setupInput(input, isStart) {
    const element = input.nativeElement;
    element.autocomplete = 'off';
    element.addEventListener('focus', () => {
      this.isOpen.set(true);
      element.blur();
    });
    // Handle initial value
    if (element.value) {
      try {
        const date = new Date(element.value);
        if (!isNaN(date.getTime())) {
          if (isStart) {
            this.startDate.set(date);
          } else {
            this.endDate.set(date);
          }
        }
      } catch (e) {
        console.warn('Invalid date value:', element.value);
      }
    }
  }
  onStartDateChange(date) {
    this.startDate.set(date);
    this.updateInputValue(this.startDateInputs(), date);
  }
  onEndDateChange(date) {
    this.endDate.set(date);
    this.updateInputValue(this.endDateInputs(), date);
    if (this.startDate() && date) {
      this.isOpen.set(false);
    }
  }
  updateInputValue(inputs, date) {
    inputs.forEach(input => {
      if (this.masking()) {
        input.nativeElement.value = this.#datePipe.transform(date, this.masking()) ?? '';
      } else {
        input.nativeElement.value = date ? date.toUTCString() : '';
      }
      this.dispatchInputEvent(input.nativeElement);
    });
  }
  dispatchInputEvent(input) {
    input.dispatchEvent(new Event('input', {
      bubbles: true
    }));
    input.dispatchEvent(new Event('change', {
      bubbles: true
    }));
  }
  open($event) {
    $event.stopPropagation();
    this.isOpen.set(true);
  }
  close() {
    this.closed.emit({
      start: this.startDate(),
      end: this.endDate()
    });
  }
  static {
    this.ɵfac = function SparkleDateRangeInputComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleDateRangeInputComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleDateRangeInputComponent,
      selectors: [["spk-daterange-input"]],
      contentQueries: function SparkleDateRangeInputComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.startDateInputs, _c19, 4);
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.endDateInputs, _c20, 4);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance(2);
        }
      },
      inputs: {
        monthsToShow: [1, "monthsToShow"],
        masking: [1, "masking"],
        isOpen: [1, "isOpen"]
      },
      outputs: {
        closed: "closed",
        isOpen: "isOpenChange"
      },
      features: [i0.ɵɵProvidersFeature([DatePipe])],
      ngContentSelectors: _c18,
      decls: 14,
      vars: 5,
      consts: [["inputWrap", ""], [3, "click", "closed", "isOpenChange", "isOpen"], ["ngProjectAs", "input", 5, ["input"], 1, "input"], [1, "masked-value"], ["suffix", "", 1, "default-indicator"], ["popoverContent", ""], [3, "date", "endDate", "class", "monthsToShow", "asRange"], [1, "masked-value", 3, "click"], [3, "dateChange", "endDateChange", "date", "endDate", "monthsToShow", "asRange"]],
      template: function SparkleDateRangeInputComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c17);
          i0.ɵɵelementStart(0, "spk-form-field-popover", 1);
          i0.ɵɵlistener("click", function SparkleDateRangeInputComponent_Template_spk_form_field_popover_click_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.open($event));
          })("closed", function SparkleDateRangeInputComponent_Template_spk_form_field_popover_closed_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.close());
          });
          i0.ɵɵtwoWayListener("isOpenChange", function SparkleDateRangeInputComponent_Template_spk_form_field_popover_isOpenChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            i0.ɵɵtwoWayBindingSet(ctx.isOpen, $event) || (ctx.isOpen = $event);
            return i0.ɵɵresetView($event);
          });
          i0.ɵɵprojection(1, 0, ["ngProjectAs", "label", 5, ["label"]]);
          i0.ɵɵprojection(2, 1, ["ngProjectAs", "[prefix]", 5, ["", "prefix", ""]]);
          i0.ɵɵprojection(3, 2, ["ngProjectAs", "[textPrefix]", 5, ["", "textPrefix", ""]]);
          i0.ɵɵelementStart(4, "div", 2, 0);
          i0.ɵɵtemplate(6, SparkleDateRangeInputComponent_Conditional_6_Template, 2, 2, "div", 3);
          i0.ɵɵprojection(7, 3);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(8, 4, ["ngProjectAs", "[textSuffix]", 5, ["", "textSuffix", ""]]);
          i0.ɵɵprojection(9, 5, ["ngProjectAs", "[suffix]", 5, ["", "suffix", ""]]);
          i0.ɵɵelementStart(10, "spk-icon", 4);
          i0.ɵɵtext(11, "calendar");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(12, "div", 5);
          i0.ɵɵtemplate(13, SparkleDateRangeInputComponent_Conditional_13_Template, 1, 6, "spk-datepicker", 6);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵclassMap("columns-" + ctx.monthsToShow());
          i0.ɵɵtwoWayProperty("isOpen", ctx.isOpen);
          i0.ɵɵadvance(6);
          i0.ɵɵconditional(ctx.masking() ? 6 : -1);
          i0.ɵɵadvance(7);
          i0.ɵɵconditional(ctx.isOpen() ? 13 : -1);
        }
      },
      dependencies: [SparkleDatepickerComponent, SparkleFormFieldPopoverComponent, SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleDateRangeInputComponent, [{
    type: Component,
    args: [{
      selector: 'spk-daterange-input',
      imports: [SparkleDatepickerComponent, SparkleFormFieldPopoverComponent, SparkleIconComponent],
      providers: [DatePipe],
      template: `
    <spk-form-field-popover
      [class]="'columns-' + monthsToShow()"
      (click)="open($event)"
      (closed)="close()"
      [(isOpen)]="isOpen">
      <ng-content select="label" ngProjectAs="label" />

      <ng-content select="[prefix]" ngProjectAs="[prefix]" />
      <ng-content select="[textPrefix]" ngProjectAs="[textPrefix]" />

      <div class="input" ngProjectAs="input" #inputWrap>
        @if (this.masking()) {
          <div class="masked-value" (click)="open($event)">
            {{ _maskedStartDate() ?? 'N/A' }} - {{ _maskedEndDate() ?? 'N/A' }}
          </div>
        }
        <ng-content select="input" />
      </div>

      <ng-content select="[textSuffix]" ngProjectAs="[textSuffix]" />
      <ng-content select="[suffix]" ngProjectAs="[suffix]" />
      <spk-icon class="default-indicator" suffix>calendar</spk-icon>

      <div popoverContent>
        @if (this.isOpen()) {
          <spk-datepicker
            [date]="startDate()"
            [endDate]="endDate()"
            [class]="classes"
            (dateChange)="onStartDateChange($event)"
            (endDateChange)="onEndDateChange($event)"
            [monthsToShow]="monthsToShow()"
            [asRange]="true" />
        }
      </div>
    </spk-form-field-popover>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [], null);
})();
class SparkleDividerComponent {
  static {
    this.ɵfac = function SparkleDividerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleDividerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleDividerComponent,
      selectors: [["spk-divider"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleDividerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleDividerComponent, [{
    type: Component,
    args: [{
      selector: 'spk-divider',
      imports: [],
      template: `
    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleFormFieldComponent {
  #selfRef = inject(ElementRef);
  onClick() {
    if (this.#selfRef.nativeElement.querySelector('input')) {
      this.#selfRef.nativeElement.querySelector('input').focus();
    }
    if (this.#selfRef.nativeElement.querySelector('textarea')) {
      this.#selfRef.nativeElement.querySelector('textarea').focus();
    }
  }
  ngOnInit() {
    const supportFieldSizing = typeof CSS !== 'undefined' && CSS.supports('field-sizing', 'content');
    const text = this.#selfRef.nativeElement.querySelector('textarea');
    if (!supportFieldSizing && text !== null) {
      function resize() {
        text.style.height = 'auto';
        text.style.height = text.scrollHeight + 'px';
      }
      /* 0-timeout to get the already changed text */
      function delayedResize() {
        window.setTimeout(resize, 0);
      }
      text.addEventListener('change', resize);
      text.addEventListener('cut', delayedResize);
      text.addEventListener('paste', delayedResize);
      text.addEventListener('drop', delayedResize);
      text.addEventListener('keydown', delayedResize);
      text.focus();
      text.select();
      resize();
    }
  }
  static {
    this.ɵfac = function SparkleFormFieldComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleFormFieldComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleFormFieldComponent,
      selectors: [["spk-form-field"]],
      hostBindings: function SparkleFormFieldComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function SparkleFormFieldComponent_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      ngContentSelectors: _c22,
      decls: 16,
      vars: 0,
      consts: [[1, "input-wrap"], [1, "prefix"], [1, "prefix-space"], [1, "suffix-space"], [1, "helpers"], [1, "error"], [1, "hint"]],
      template: function SparkleFormFieldComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c21);
          i0.ɵɵprojection(0);
          i0.ɵɵelementStart(1, "div", 0)(2, "div", 1);
          i0.ɵɵprojection(3, 1);
          i0.ɵɵprojection(4, 2);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(5, "div", 2);
          i0.ɵɵprojection(6, 3);
          i0.ɵɵprojection(7, 4);
          i0.ɵɵprojection(8, 5);
          i0.ɵɵelement(9, "div", 3);
          i0.ɵɵprojection(10, 6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(11, "div", 4)(12, "div", 5);
          i0.ɵɵprojection(13, 7);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(14, "div", 6);
          i0.ɵɵprojection(15, 8);
          i0.ɵɵelementEnd()();
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleFormFieldComponent, [{
    type: Component,
    args: [{
      selector: 'spk-form-field',
      imports: [],
      template: `
    <ng-content select="label"></ng-content>

    <div class="input-wrap">
      <div class="prefix">
        <ng-content select="[prefix]"></ng-content>
        <ng-content select="[textPrefix]"></ng-content>
      </div>

      <div class="prefix-space"></div>

      <ng-content select="input"></ng-content>
      <ng-content select="textarea"></ng-content>

      <ng-content select="[textSuffix]"></ng-content>
      <div class="suffix-space"></div>
      <ng-content select="[suffix]"></ng-content>
    </div>

    <div class="helpers">
      <div class="error">
        <ng-content select="[error]"></ng-content>
      </div>

      <div class="hint">
        <ng-content select="[hint]"></ng-content>
      </div>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class SparkleFileUploadComponent {
  constructor() {
    this.#selfRef = inject(ElementRef);
    this.inputWrapRef = viewChild.required('inputWrap');
    this.#inputRef = signal(null);
    this.#triggerInput = signal(false);
    this.files = model([]);
    this.inputRefEffect = effect(() => {
      this.#triggerInput();
      const input = this.#selfRef.nativeElement.querySelector('input');
      if (input) {
        input.autocomplete = 'off';
        this.#inputRef.set(input);
        this.#newInput();
      }
    });
    this.inputController = null;
    this.#inputObserver = typeof MutationObserver !== 'undefined' && new MutationObserver(mutations => {
      for (var mutation of mutations) {
        if (mutation.type == 'childList') {
          this.#triggerInput.set(!this.#triggerInput());
        }
      }
    });
  }
  #selfRef;
  #inputRef;
  #triggerInput;
  ngOnInit() {
    if (typeof MutationObserver !== 'undefined') {
      this.#inputObserver.observe(this.inputWrapRef().nativeElement, {
        childList: true,
        subtree: true
      });
    }
  }
  onFileDropped(files) {
    this.handleFileUpload(Array.from(files));
  }
  handleFileUpload(newFiles) {
    this.files.update(currentFiles => [...currentFiles, ...newFiles]);
  }
  #newInput() {
    if (this.inputController) {
      this.inputController.abort();
    }
    this.inputController = new AbortController();
    const input = this.#inputRef();
    if (!input) return;
    input.addEventListener('change', e => {
      const files = e.target.files;
      if (files && files.length > 0) {
        this.handleFileUpload(Array.from(files));
      }
    });
  }
  #inputObserver;
  ngOnDestroy() {
    if (this.#inputObserver) {
      this.#inputObserver.disconnect();
    }
  }
  static {
    this.ɵfac = function SparkleFileUploadComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleFileUploadComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleFileUploadComponent,
      selectors: [["spk-file-upload"]],
      viewQuery: function SparkleFileUploadComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.inputWrapRef, _c16, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        files: [1, "files"]
      },
      outputs: {
        files: "filesChange"
      },
      ngContentSelectors: _c24,
      decls: 7,
      vars: 0,
      consts: [["inputWrap", ""], [3, "fileDropped"], ["ngProjectAs", "input", 5, ["input"], 1, "input"], ["suffix", ""]],
      template: function SparkleFileUploadComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c23);
          i0.ɵɵelementStart(0, "spk-form-field", 1);
          i0.ɵɵlistener("fileDropped", function SparkleFileUploadComponent_Template_spk_form_field_fileDropped_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onFileDropped($event));
          });
          i0.ɵɵprojection(1, 0, ["ngProjectAs", "label", 5, ["label"]]);
          i0.ɵɵelementStart(2, "div", 2, 0);
          i0.ɵɵprojection(4, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "spk-icon", 3);
          i0.ɵɵtext(6, "upload-simple");
          i0.ɵɵelementEnd()();
        }
      },
      dependencies: [SparkleFormFieldComponent, SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleFileUploadComponent, [{
    type: Component,
    args: [{
      selector: 'spk-file-upload',
      imports: [SparkleFormFieldComponent, SparkleIconComponent],
      template: `
    <spk-form-field (fileDropped)="onFileDropped($any($event))">
      <ng-content select="label" ngProjectAs="label"></ng-content>

      <div class="input" ngProjectAs="input" #inputWrap>
        <ng-content select="input"></ng-content>
      </div>

      <spk-icon suffix>upload-simple</spk-icon>
    </spk-form-field>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleListComponent {
  static {
    this.ɵfac = function SparkleListComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleListComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleListComponent,
      selectors: [["spk-list"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleListComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleListComponent, [{
    type: Component,
    args: [{
      selector: 'spk-list',
      imports: [],
      template: `
    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
function createInputSignal(input, options) {
  const injector = options?.injector || (assertInInjectionContext(createInputSignal), inject(Injector));
  const {
    debounce = 0,
    initialValue = undefined,
    transform = value => value,
    compare = (a, b) => a === b,
    forceType = undefined,
    returnPreviousValue = true
  } = options || {};
  const valueSignal = signal(initialValue);
  const destroyRef = injector.get(DestroyRef);
  const inputElementRef = computed(() => {
    const inputElement = input()?.nativeElement;
    if (!(inputElement instanceof HTMLInputElement || inputElement instanceof HTMLTextAreaElement)) {
      return;
    }
    return createCustomInputEventListener(inputElement);
  });
  let isUpdating = false;
  let previousValue;
  let timeoutId = null;
  effect(() => {
    const inputElement = inputElementRef();
    if (!inputElement) {
      return valueSignal.set(returnPreviousValue && previousValue ? transform(previousValue) : undefined);
    }
    if (initialValue !== undefined && inputElement.value === '') {
      valueSignal.set(initialValue);
    } else if (inputElement.value !== '') {
      syncValueFromInput();
    }
    const inputHandler = e => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        timeoutId = null;
        syncValueFromInput();
      }, debounce);
    };
    inputElement.addEventListener('input', inputHandler);
    inputElement.addEventListener('inputValueChanged', inputHandler);
    destroyRef.onDestroy(() => {
      inputElement.removeEventListener('input', inputHandler);
      inputElement.removeEventListener('inputValueChanged', inputHandler);
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    });
  }, {
    injector
  });
  effect(() => {
    const inputElement = inputElementRef();
    if (!inputElement) return;
    const currentValue = valueSignal() ?? '';
    let domValue = currentValue === null || currentValue === undefined ? '' : String(currentValue);
    if (inputElement.value !== domValue) {
      previousValue = domValue;
      inputElement.value = domValue;
      inputElement.dispatchEvent(new Event('input'));
    }
  }, {
    injector
  });
  return valueSignal;
  function syncValueFromInput() {
    if (isUpdating) return;
    isUpdating = true;
    try {
      const inputElement = inputElementRef();
      if (!inputElement) return;
      const inputValue = inputElement.value;
      const transformedValue = forceType ? forceTransform(inputValue, forceType) : transform(inputValue);
      previousValue = inputValue;
      if (!compare(valueSignal(), transformedValue)) {
        valueSignal.set(transformedValue);
      }
    } finally {
      isUpdating = false;
    }
  }
  function createCustomInputEventListener(input) {
    Object.defineProperty(input, 'value', {
      configurable: true,
      get() {
        const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(input), 'value'); // Use Object.getPrototypeOf
        return descriptor.get.call(this);
      },
      set(newVal) {
        const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(input), 'value'); // Use Object.getPrototypeOf
        descriptor.set.call(this, newVal);
        const inputEvent = new CustomEvent('inputValueChanged', {
          bubbles: true,
          cancelable: true,
          detail: {
            value: newVal
          }
        });
        this.dispatchEvent(inputEvent);
        return newVal;
      }
    });
    return input;
  }
  function forceTransform(value, type) {
    switch (type) {
      case 'string':
        return value.toString();
      case 'number':
        const num = Number(value);
        return isNaN(num) ? undefined : num;
      case 'boolean':
        return value.toLowerCase() === 'true' ? true : value.toLowerCase() === 'false' ? false : undefined;
      default:
        return value;
    }
  }
}
class SparkleMenuComponent {
  constructor() {
    this.above = input(false);
    this.right = input(false);
    this.keepClickedOptionActive = input(false);
    this.isOpen = model(false);
    this.closed = output();
    this.searchable = input(false);
    this.activeOptionIndex = signal(-1);
    this.inputRef = viewChild('input');
    this.options = contentChildren('option', {
      descendants: true
    });
    this.optionsEl = computed(() => {
      return Array.from(this.options()).map(x => x.nativeElement).filter(x => !x.disabled);
    });
    this.inputValue = createInputSignal(this.inputRef);
    this.abortController = null;
    this.optionsEffect = effect(() => {
      if (!this.isOpen()) return;
      if (this.abortController) {
        this.abortController.abort();
        this.abortController = null;
      }
      this.abortController = new AbortController();
      const inputEl = this.inputRef()?.nativeElement;
      if (!inputEl) return;
      queueMicrotask(() => inputEl.focus());
      inputEl.addEventListener('keydown', e => {
        const optionElements = this.optionsEl();
        const activeOptionIndex = this.activeOptionIndex();
        if (e.key === 'ArrowDown') {
          e.preventDefault();
          this.activeOptionIndex.set(this.nextActiveIndex(activeOptionIndex));
        } else if (e.key === 'ArrowUp') {
          e.preventDefault();
          this.activeOptionIndex.set(this.prevActiveIndex(activeOptionIndex));
        } else if (e.key === 'Enter') {
          e.preventDefault();
          if (activeOptionIndex > -1) {
            optionElements[activeOptionIndex].click();
            queueMicrotask(() => this.close('active'));
          }
        } else if (e.key === 'Tab') {
          e.preventDefault();
          this.close('closed');
        }
      }, {
        signal: this.abortController.signal
      });
    });
    this.inputValueEffect = effect(() => {
      const searchable = this.searchable();
      if (!searchable) return;
      const inputValue = (this.inputValue() ?? '').toLowerCase();
      const optionElements = this.optionsEl();
      if (!inputValue || inputValue === '') {
        optionElements.forEach(el => {
          el.classList.remove('hide-option');
          el.style.order = '';
        });
        return;
      }
      const scoredOptions = optionElements.map(el => {
        const textContent = el.textContent?.toLowerCase() || '';
        const score = this.#calculateMatchScore(textContent, inputValue);
        return {
          el,
          score,
          textContent
        };
      });
      scoredOptions.sort((a, b) => b.score - a.score);
      let firstFound = false;
      scoredOptions.forEach(({
        el,
        score
      }, index) => {
        if (score === 0 || el.disabled) {
          el.classList.add('hide-option');
          el.style.order = '';
        } else {
          el.classList.remove('hide-option');
          el.style.order = index.toString();
          if (!firstFound) {
            queueMicrotask(() => this.activeOptionIndex.set(optionElements.indexOf(el)));
            firstFound = true;
          }
        }
      });
    });
    this.activeOptionIndexEffect = effect(() => {
      const optionElements = this.optionsEl();
      const activeOptionIndex = this.activeOptionIndex();
      for (let index = 0; index < optionElements.length; index++) {
        optionElements[index].classList.remove('active');
      }
      if (activeOptionIndex > -1) {
        optionElements[activeOptionIndex].scrollIntoView({
          block: 'center'
        });
        optionElements[activeOptionIndex].classList.add('active');
      }
    });
  }
  #calculateMatchScore(option, input) {
    if (!input) return 0;
    let score = 0;
    let lastIndex = -1;
    let matchCount = 0;
    if (option.includes(input)) {
      score += 1000;
    }
    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      const charIndex = option.indexOf(char, lastIndex + 1);
      if (charIndex === -1) {
        return 0;
      }
      score += 100;
      if (lastIndex + 1 === charIndex && lastIndex !== -1) {
        score += 50;
      }
      lastIndex = charIndex;
      matchCount++;
    }
    score += matchCount * 20;
    return score;
  }
  nextActiveIndex(activeIndex) {
    const optionElements = this.optionsEl();
    if (activeIndex === -1) {
      return 0;
    }
    if (activeIndex === optionElements.length - 1) {
      return 0;
    }
    const nextIndex = activeIndex + 1;
    if (this.searchable()) {
      if (optionElements[nextIndex].disabled) {
        return this.nextActiveIndex(nextIndex);
      }
      if (optionElements[nextIndex].classList.contains('hide-option')) {
        return this.nextActiveIndex(nextIndex);
      }
    }
    return nextIndex;
  }
  prevActiveIndex(activeIndex) {
    const optionElements = this.optionsEl();
    if (activeIndex === 0) {
      return optionElements.length - 1;
    }
    if (activeIndex === -1) {
      return optionElements.length - 1;
    }
    const prevIndex = activeIndex - 1;
    if (this.searchable()) {
      if (optionElements[prevIndex].disabled) {
        return this.prevActiveIndex(prevIndex);
      }
      if (optionElements[prevIndex].classList.contains('hide-option')) {
        return this.prevActiveIndex(prevIndex);
      }
    }
    return prevIndex;
  }
  close(action = 'closed', event) {
    this.inputValue.set('');
    (!this.keepClickedOptionActive() || action === 'closed') && this.#resetActiveOption();
    this.isOpen.set(false);
    this.closed.emit(action === 'active');
  }
  #resetActiveOption() {
    this.activeOptionIndex.set(-1);
    const optionElements = this.optionsEl();
    for (let index = 0; index < optionElements.length; index++) {
      optionElements[index].classList.remove('active');
    }
  }
  ngOnDestroy() {
    if (this.abortController) {
      this.abortController.abort();
      this.abortController = null;
    }
  }
  createWildcardRegex(inputValue) {
    const lowerCaseInput = (inputValue ?? '').toLowerCase();
    let regexPattern = '^';
    for (const char of lowerCaseInput) {
      regexPattern += '.*' + this.escapeRegexChar(char);
    }
    regexPattern += '.*$';
    return new RegExp(regexPattern, 'i');
  }
  escapeRegexChar(char) {
    return char.replace(/[-\/\\^$+?.()|[\]{}]/g, '\\$&');
  }
  static {
    this.ɵfac = function SparkleMenuComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleMenuComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleMenuComponent,
      selectors: [["spk-menu"]],
      contentQueries: function SparkleMenuComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.options, _c25, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      viewQuery: function SparkleMenuComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.inputRef, _c26, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        above: [1, "above"],
        right: [1, "right"],
        keepClickedOptionActive: [1, "keepClickedOptionActive"],
        isOpen: [1, "isOpen"],
        searchable: [1, "searchable"]
      },
      outputs: {
        isOpen: "isOpenChange",
        closed: "closed"
      },
      ngContentSelectors: _c28,
      decls: 9,
      vars: 10,
      consts: [["formFieldWrapper", ""], ["input", ""], [3, "isOpenChange", "closed", "isOpen", "disableOpenByClick", "above", "right", "options"], ["trigger", "", 3, "click"], [1, "small"], ["type", "text", "placeholder", "Search"], [1, "options", 3, "click"]],
      template: function SparkleMenuComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c27);
          i0.ɵɵelementStart(0, "spk-popover", 2, 0);
          i0.ɵɵtwoWayListener("isOpenChange", function SparkleMenuComponent_Template_spk_popover_isOpenChange_0_listener($event) {
            i0.ɵɵrestoreView(_r1);
            i0.ɵɵtwoWayBindingSet(ctx.isOpen, $event) || (ctx.isOpen = $event);
            return i0.ɵɵresetView($event);
          });
          i0.ɵɵlistener("closed", function SparkleMenuComponent_Template_spk_popover_closed_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.close("fromPopover"));
          });
          i0.ɵɵelementStart(2, "div", 3);
          i0.ɵɵlistener("click", function SparkleMenuComponent_Template_div_click_2_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.isOpen.set(true));
          });
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "spk-form-field", 4);
          i0.ɵɵelement(5, "input", 5, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "div", 6);
          i0.ɵɵlistener("click", function SparkleMenuComponent_Template_div_click_7_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.close("active"));
          });
          i0.ɵɵprojection(8, 1);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵtwoWayProperty("isOpen", ctx.isOpen);
          i0.ɵɵproperty("disableOpenByClick", true)("above", ctx.above())("right", ctx.right())("options", i0.ɵɵpureFunction0(9, _c14));
          i0.ɵɵadvance(4);
          i0.ɵɵclassProp("hidden", ctx.searchable() === false);
          i0.ɵɵadvance(3);
          i0.ɵɵclassProp("searching", ctx.searchable() && ctx.inputValue() !== "");
        }
      },
      dependencies: [SparklePopoverComponent, SparkleFormFieldComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleMenuComponent, [{
    type: Component,
    args: [{
      selector: 'spk-menu',
      imports: [SparklePopoverComponent, SparkleFormFieldComponent],
      template: `
    <spk-popover
      #formFieldWrapper
      [(isOpen)]="isOpen"
      [disableOpenByClick]="true"
      (closed)="close('fromPopover')"
      [above]="above()"
      [right]="right()"
      [options]="{
        closeOnButton: false,
        closeOnEsc: true,
      }">
      <div trigger (click)="isOpen.set(true)">
        <ng-content />
      </div>

      <spk-form-field class="small" [class.hidden]="searchable() === false">
        <input type="text" #input placeholder="Search" />
      </spk-form-field>

      <div class="options" (click)="close('active')" [class.searching]="searchable() && inputValue() !== ''">
        <ng-content select="[menu]" />
      </div>
    </spk-popover>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleProgressBarComponent {
  constructor() {
    this.value = input(undefined);
  }
  static {
    this.ɵfac = function SparkleProgressBarComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleProgressBarComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleProgressBarComponent,
      selectors: [["spk-progress-bar"]],
      inputs: {
        value: [1, "value"]
      },
      decls: 1,
      vars: 2,
      consts: [[1, "progress-bar"]],
      template: function SparkleProgressBarComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 0);
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("width", ctx.value(), "%");
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleProgressBarComponent, [{
    type: Component,
    args: [{
      selector: 'spk-progress-bar',
      imports: [],
      template: `
    <div class="progress-bar" [style.width.%]="value()"></div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleRadioComponent {
  #selfRef = inject(ElementRef);
  onClick() {
    if (this.#selfRef.nativeElement.querySelector('input')) {
      this.#selfRef.nativeElement.querySelector('input').focus();
    }
  }
  static {
    this.ɵfac = function SparkleRadioComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleRadioComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleRadioComponent,
      selectors: [["spk-radio"]],
      hostBindings: function SparkleRadioComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function SparkleRadioComponent_click_HostBindingHandler() {
            return ctx.onClick();
          });
        }
      },
      ngContentSelectors: _c0,
      decls: 2,
      vars: 0,
      consts: [[1, "radio"]],
      template: function SparkleRadioComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelement(0, "div", 0);
          i0.ɵɵprojection(1);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleRadioComponent, [{
    type: Component,
    args: [{
      selector: 'spk-radio',
      imports: [],
      template: `
    <div class="radio"></div>

    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    onClick: [{
      type: HostListener,
      args: ['click']
    }]
  });
})();
class SparkleRangeSliderComponent {
  constructor() {
    this.#selfRef = inject(ElementRef);
    this.#observer = null;
    this.unit = input('');
    this.value = model(0);
    this.inputState = signal({
      min: 0,
      max: 100
      // value: 0,
    });
    this.inputEffect = effect(() => {
      const newVal = this.value();
      if (this.inputField && newVal !== parseInt(this.inputField.value)) {
        this.inputField.value = newVal + '';
      }
    });
  }
  #selfRef;
  #observer;
  get inputField() {
    return this.#selfRef.nativeElement.querySelector('input[type="range"]') ?? null;
  }
  trackEvent(e) {
    if (this.inputField?.readOnly) {
      e.preventDefault();
      e.stopPropagation();
    }
  }
  ngOnInit() {
    if (this.inputField) {
      this.inputState.set({
        max: parseInt(this.inputField.max ?? '') ?? 100,
        min: parseInt(this.inputField.min ?? '') ?? 0
      });
      this.value.set(parseInt(this.inputField.value ?? '') ?? 0);
      this.inputField.oninput = e => {
        this.value.set(parseInt(this.inputField.value ?? '') ?? 0);
      };
      const MUTATION_FIELDS = ['min', 'max', 'value'];
      if (typeof MutationObserver !== 'undefined') {
        this.#observer = new MutationObserver((mutationList, _) => {
          for (const mutation of mutationList) {
            if (mutation.type === 'attributes' && MUTATION_FIELDS.includes(mutation.attributeName ?? '')) {
              this.inputState.set({
                max: parseInt(this.inputField.max ?? '') ?? 100,
                min: parseInt(this.inputField.min ?? '') ?? 0
              });
              this.value.set(parseInt(this.inputField.value ?? '') ?? 0);
            }
          }
        });
        this.#observer.observe(this.inputField, {
          attributes: true,
          childList: false,
          subtree: false
        });
      }
    } else {
      console.error('No input field found');
    }
  }
  thumbWrapStyle() {
    return {
      left: `${this.value() / this.inputState().max * 100}%`
    };
  }
  thumbStyle() {
    return {
      transform: `translateX(-${this.value() / this.inputState().max * 100}%)`
    };
  }
  trackFilledStyle() {
    return {
      width: `${this.value() / this.inputState().max * 100}%`
    };
  }
  ngOnDestroy() {
    if (this.#observer) {
      this.#observer.disconnect();
    }
  }
  static {
    this.ɵfac = function SparkleRangeSliderComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleRangeSliderComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleRangeSliderComponent,
      selectors: [["spk-range-slider"]],
      hostVars: 2,
      hostBindings: function SparkleRangeSliderComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("has-input", ctx.inputField);
        }
      },
      inputs: {
        unit: [1, "unit"],
        value: [1, "value"]
      },
      outputs: {
        value: "valueChange"
      },
      ngContentSelectors: _c30,
      decls: 15,
      vars: 12,
      consts: [[1, "label"], [1, "input-wrap"], [1, "min-indicator"], [1, "track-wrap", 3, "click"], [1, "track"], [1, "track-filled"], [1, "thumb-wrap"], [1, "thumb"], [1, "value-indicator"], [1, "max-indicator"]],
      template: function SparkleRangeSliderComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c29);
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵprojection(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1)(3, "div", 2);
          i0.ɵɵtext(4);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "div", 3);
          i0.ɵɵlistener("click", function SparkleRangeSliderComponent_Template_div_click_5_listener($event) {
            return ctx.trackEvent($event);
          });
          i0.ɵɵprojection(6, 1);
          i0.ɵɵelementStart(7, "div", 4);
          i0.ɵɵelement(8, "div", 5);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(9, "div", 6)(10, "div", 7)(11, "div", 8);
          i0.ɵɵtext(12);
          i0.ɵɵelementEnd()()()();
          i0.ɵɵelementStart(13, "div", 9);
          i0.ɵɵtext(14);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(4);
          i0.ɵɵtextInterpolate2("", ctx.inputState().min, "", ctx.unit(), "");
          i0.ɵɵadvance(4);
          i0.ɵɵstyleMap(ctx.trackFilledStyle());
          i0.ɵɵadvance();
          i0.ɵɵstyleMap(ctx.thumbWrapStyle());
          i0.ɵɵadvance();
          i0.ɵɵstyleMap(ctx.thumbStyle());
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate2("", ctx.value(), "", ctx.unit(), "");
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate2("", ctx.inputState().max, "", ctx.unit(), "");
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleRangeSliderComponent, [{
    type: Component,
    args: [{
      selector: 'spk-range-slider',
      imports: [],
      template: `
    <div class="label">
      <ng-content select="label"></ng-content>
    </div>

    <div class="input-wrap">
      <div class="min-indicator">{{ inputState().min }}{{ unit() }}</div>

      <div class="track-wrap" (click)="trackEvent($event)">
        <ng-content></ng-content>
        <div class="track">
          <div class="track-filled" [style]="trackFilledStyle()"></div>
        </div>
        <div class="thumb-wrap" [style]="thumbWrapStyle()">
          <div class="thumb" [style]="thumbStyle()">
            <div class="value-indicator">{{ value() }}{{ unit() }}</div>
          </div>
        </div>
      </div>

      <div class="max-indicator">{{ inputState().max }}{{ unit() }}</div>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, {
    inputField: [{
      type: HostBinding,
      args: ['class.has-input']
    }]
  });
})();
class SparkleSpinnerComponent {
  static {
    this.ɵfac = function SparkleSpinnerComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleSpinnerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleSpinnerComponent,
      selectors: [["spk-spinner"]],
      decls: 0,
      vars: 0,
      template: function SparkleSpinnerComponent_Template(rf, ctx) {},
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleSpinnerComponent, [{
    type: Component,
    args: [{
      selector: 'spk-spinner',
      imports: [],
      template: ``,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleSelectComponent {
  constructor() {
    this.#selfRef = inject(ElementRef);
    this.value = input();
    this.label = input();
    this.asFreeText = input(false);
    this.placeholder = input();
    this.readonly = model(false);
    this.disabled = model(false);
    this.lazySearch = input(false);
    this.inlineSearch = input(false);
    this.asText = input(false);
    this.isClearable = input(true);
    this.selectMultiple = input(false);
    this.optionTemplate = input(null);
    this.selectedOptionTemplate = input(null);
    this.placeholderTemplate = input(null);
    this.isOpen = model(false);
    this.isLoading = model(false);
    this.options = model([]);
    this.selectedOptions = model([]);
    this.cleared = output();
    this.inlineTemplate = contentChild(TemplateRef);
    this.optionsWrapRef = viewChild.required('optionsWrap');
    this.inputRefInput = signal(null);
    this.#inputObserver = typeof MutationObserver !== 'undefined' && new MutationObserver(mutations => {
      for (var mutation of mutations) {
        if (mutation && (mutation.target.nodeName === 'INPUT' || mutation.target.nodeName === 'TEXTAREA')) {
          this.inputRefInput.set(new ElementRef(mutation.target));
          this.#inputObserver.disconnect();
        }
      }
    });
    this.inputValue = signal('');
    this.prevInputValue = signal(null);
    this.focusedOptionIndex = signal(-1);
    this._isClearable = computed(() => this.selectMultiple() || this.isClearable());
    this.selectClasses = computed(() => this.#selfRef.nativeElement.classList.toString());
    this.placeholderText = computed(() => {
      const placeholder = this.placeholder();
      const inputRefEl = this.inputRefEl();
      return placeholder || inputRefEl?.placeholder || null;
    });
    this.selectedOptionValues = computed(() => {
      const selectedOptions = this.selectedOptions();
      const valueKey = this.value();
      return valueKey ? selectedOptions.map(selectedOption => this.#getProperty(selectedOption, valueKey)) : selectedOptions;
    });
    this.filteredOptions = computed(() => {
      const opts = this.options() || [];
      const label = this.label();
      const valueKey = this.value();
      const inlineSearch = this.inlineSearch();
      const inputValue = this.inputValue().toLowerCase();
      const inputValueRegex = this.#createWildcardRegex(inputValue);
      if (opts.length <= 0) return [];
      if (!inlineSearch || inputValue === '') {
        return opts;
      }
      const filtered = opts.filter(item => {
        const optionLabel = label ? (this.#getProperty(item, label) ?? '').toString().toLowerCase() : (item ?? '').toString().toLowerCase();
        const optionValue = ((valueKey ? this.#getProperty(item, valueKey) : item) ?? '').toString().toLowerCase();
        const testLabel = inputValueRegex.test(optionLabel);
        const testValue = inputValueRegex.test(optionValue);
        return testLabel || testValue;
      });
      const scoredOptions = filtered.map(item => {
        const optionLabel = label ? (this.#getProperty(item, label) ?? '').toString().toLowerCase() : (item ?? '').toString().toLowerCase();
        const optionValue = ((valueKey ? this.#getProperty(item, valueKey) : item) ?? '').toString().toLowerCase();
        const labelScore = this.#calculateMatchScore(optionLabel, inputValue);
        const valueScore = this.#calculateMatchScore(optionValue, inputValue);
        return {
          item,
          score: Math.max(labelScore, valueScore)
        };
      });
      scoredOptions.sort((a, b) => b.score - a.score);
      return scoredOptions.map(scoredOption => scoredOption.item);
    });
    this.inputRefEl = computed(() => {
      const inputRefInput = this.inputRefInput();
      if (inputRefInput === null) return;
      const input = inputRefInput ? inputRefInput.nativeElement : null;
      if (!input) {
        console.warn('<spk-select> input element not found are you missing to pass an <input> or <textarea> element to select component?');
        return null;
      }
      input.autocomplete = 'off';
      this.#createCustomInputEventListener(input);
      input.addEventListener('focus', () => {
        if (this.readonly()) return;
        this.open();
      });
      if (this.hasSearch()) {
        input.addEventListener('input', e => {
          const newInputValue = e.target.value;
          const inputValue = this.inputValue();
          if (newInputValue === inputValue) return;
          this.focusedOptionIndex.set(this.asFreeText() ? -1 : 0);
          this.inputValue.set(newInputValue);
          this.updateInputElValue();
        });
      }
      input.addEventListener('inputValueChanged', event => {
        const newInputValue = event.detail.value;
        const inputValue = this.inputValue();
        if (newInputValue === inputValue) return;
        if (newInputValue === '') {
          this.clear();
          return;
        }
        this.setSelectedOptionsFromValue(newInputValue);
        this.setInputValueFromSelectedOptions();
        this.#setFirstSelectedOptionAsFocused();
      });
      return input;
    });
    this.openAbortController = null;
    this.isOpenEffect = effect(() => {
      const isOpen = this.isOpen();
      if (isOpen) {
        if (!this.openAbortController) {
          this.openAbortController = new AbortController();
        }
        const input = this.inputRefEl();
        if (!input) return;
        input.addEventListener('keydown', e => {
          if (e.key === 'Escape' || e.key === 'Tab') {
            e.preventDefault();
            this.close();
          }
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            this.toggleOptionByIndex(this.focusedOptionIndex());
          }
          if (e.key === 'ArrowDown') {
            e.preventDefault();
            const newIndex = this.focusedOptionIndex() + 1;
            this.focusedOptionIndex.set(newIndex > this.filteredOptions().length - 1 ? 0 : newIndex);
          }
          if (e.key === 'ArrowUp') {
            e.preventDefault();
            const newIndex = this.focusedOptionIndex() - 1;
            this.focusedOptionIndex.set(newIndex < 0 ? this.filteredOptions().length - 1 : newIndex);
          }
        }, {
          signal: this.openAbortController?.signal
        });
      } else {
        const input = this.inputRefEl();
        if (!input) return;
        if (this.openAbortController) {
          this.openAbortController.abort();
          this.openAbortController = null;
        }
      }
    });
    this._inputValue = '';
    this.inputValueEffect = effect(() => {
      const inputValue = this.inputValue();
      this._inputValue = inputValue;
    });
    this.inputRefElEffect = effect(() => {
      const input = this.inputRefEl();
      if (!input) return;
      if (input.value === this._inputValue) return;
      this.disabled.set(input.disabled);
      this.setSelectedOptionsFromValue(input.value);
      this.setInputValueFromSelectedOptions();
    });
    this.selectedLabels = computed(() => {
      const selected = this.selectedOptions();
      const label = this.label();
      if (!label) {
        return selected.join(', ');
      }
      return selected.map(selected => this.getLabel(selected)).join(', ');
    });
    this.inputState = computed(() => {
      if ((this.selectedOptions().length > 0 || this.asFreeText() && this.inputValue().length > 0) && !this.isOpen()) {
        return 'selected';
      }
      if (this.isLoading()) {
        return 'loading';
      }
      if (this.isOpen() && (this.lazySearch() || this.inlineSearch())) {
        return 'open-searching';
      }
      if (this.isOpen()) {
        return 'open';
      }
      if (this.inlineSearch() || this.lazySearch()) {
        return 'searching';
      }
      return 'closed';
    });
    this.hasSearch = computed(() => this.inlineSearch() || this.lazySearch());
    this.#selectedOptionsEffect = effect(() => {
      if (this.selectMultiple() && this.hasSearch()) {
        return;
      }
      const selectedOptions = this.selectedOptions();
      const valueKey = this.value();
      const inputValue = selectedOptions.map(option => {
        const optionValue = valueKey ? this.#getProperty(option, valueKey) : option;
        return optionValue;
      }).join(',');
      this.inputValue.set(inputValue);
    });
  }
  #selfRef;
  #inputObserver;
  #calculateMatchScore(option, input) {
    if (!input) return 0;
    let score = 0;
    let lastIndex = -1;
    let matchCount = 0;
    if (option.includes(input)) {
      score += 1000;
    }
    for (let i = 0; i < input.length; i++) {
      const char = input[i];
      const charIndex = option.indexOf(char, lastIndex + 1);
      if (charIndex === -1) {
        return 0;
      }
      score += 100;
      if (lastIndex + 1 === charIndex && lastIndex !== -1) {
        score += 50;
      }
      lastIndex = charIndex;
      matchCount++;
    }
    score += matchCount * 20;
    return score;
  }
  #selectedOptionsEffect;
  ngOnInit() {
    this.setInitInput();
  }
  setInitInput() {
    const input = this.#selfRef.nativeElement.querySelector('input');
    if (input) {
      this.inputRefInput.set(new ElementRef(input));
      return;
    }
    const textarea = this.#selfRef.nativeElement.querySelector('textarea');
    if (textarea) {
      this.inputRefInput.set(new ElementRef(textarea));
      return;
    }
    if (typeof MutationObserver !== 'undefined') {
      this.#inputObserver.observe(this.#selfRef.nativeElement, {
        attributes: true,
        childList: true,
        subtree: true,
        characterData: true
      });
    }
  }
  setSelectedOptionsFromValue(value) {
    const options = this.options() || [];
    const valueKey = this.value();
    const selectMultiple = this.selectMultiple();
    const inputValueAsString = value.toString().split(',');
    if (inputValueAsString.length === 0) {
      this.selectedOptions.set([]);
      return;
    }
    const inputAsArray = selectMultiple ? inputValueAsString : [inputValueAsString[0]];
    const selectedOptions = options.filter(option => {
      const optionValue = valueKey ? this.#getProperty(option, valueKey)?.toString() : option?.toString();
      return optionValue && inputAsArray.includes(optionValue);
    });
    this.selectedOptions.set(selectMultiple ? selectedOptions : [selectedOptions[0]]);
  }
  setInputValueFromSelectedOptions() {
    const selectedOptions = this.selectedOptions();
    const valueKey = this.value();
    if (selectedOptions.length === 0) {
      this.inputValue.set('');
      this.updateInputElValue();
      return;
    }
    const inputValue = selectedOptions.map(option => {
      const optionValue = valueKey ? this.#getProperty(option, valueKey) : option;
      return optionValue;
    }).join(',');
    this.inputValue.set(inputValue);
    this.updateInputElValue();
  }
  getLabel(option) {
    const label = this.label();
    if (!label) return option;
    return this.#getProperty(option, label);
  }
  toggleOptionByIndex(optionIndex, event) {
    const option = this.filteredOptions()[optionIndex];
    if (this.asFreeText() && optionIndex === -1 || !option) {
      this.close();
      return;
    }
    if (event) {
      event.stopPropagation();
    }
    const selectMultiple = this.selectMultiple();
    const isClearable = this._isClearable();
    const valueKey = this.value();
    const selectedOptionValues = this.selectedOptionValues();
    const optionValue = valueKey ? this.#getProperty(option, valueKey) : option;
    this.prevInputValue.set(null);
    this.selectedOptions.update(selectedOptions => {
      const index = selectedOptionValues.indexOf(optionValue);
      if (index > -1) {
        // Remove it
        const nextSelectedOptions = [...selectedOptions.slice(0, index), ...selectedOptions.slice(index + 1)];
        return isClearable ? nextSelectedOptions : nextSelectedOptions.length > 0 ? nextSelectedOptions : selectedOptions;
      } else {
        // Add it
        this.focusedOptionIndex.set(optionIndex);
        return selectMultiple ? [...selectedOptions, option] : [option];
      }
    });
    if (selectMultiple) {
      this.inputRefEl()?.focus();
    } else {
      this.isOpen.set(false);
    }
    this.setInputValueFromSelectedOptions();
    if (selectMultiple && this.hasSearch()) {
      this.inputValue.set('');
      this.updateInputElValue();
    }
  }
  removeSelectedOptionByIndex($event, optionRemoveIndex) {
    $event.stopPropagation();
    this.selectedOptions.update(selectedOptions => {
      return [...selectedOptions.slice(0, optionRemoveIndex), ...selectedOptions.slice(optionRemoveIndex + 1)];
    });
    this.setInputValueFromSelectedOptions();
  }
  isSelected(optionIndex) {
    const valueKey = this.value();
    const option = this.filteredOptions()[optionIndex];
    const optionValue = valueKey ? this.#getProperty(option, valueKey) : option;
    return this.selectedOptionValues().indexOf(optionValue) > -1;
  }
  open() {
    if (this.isOpen()) return;
    this.isOpen.set(true);
    if (this.hasSearch()) {
      this.prevInputValue.set(this.inputValue() ?? '');
      this.inputValue.set('');
      this.updateInputElValue();
    }
    if (!this.selectMultiple()) {
      this.#setFirstSelectedOptionAsFocused();
    }
  }
  #setFirstSelectedOptionAsFocused() {
    const firstSelectedValue = this.selectedOptionValues()[0];
    const valueKey = this.value();
    if (firstSelectedValue) {
      const findOptionIndex = this.filteredOptions().findIndex(x => valueKey ? this.#getProperty(x, valueKey) === firstSelectedValue : x === firstSelectedValue);
      this.focusedOptionIndex.set(findOptionIndex);
    } else {
      this.focusedOptionIndex.set(-1);
    }
  }
  close() {
    this.isOpen.set(false);
    const prevInputValue = this.prevInputValue();
    if (this.asFreeText()) {
      this.updateInputElValue();
      return;
    }
    if (this.hasSearch() && prevInputValue) {
      this.inputValue.set(prevInputValue);
      this.setInputValueFromSelectedOptions();
    }
    if (this.selectMultiple()) {
      this.setInputValueFromSelectedOptions();
    }
  }
  clear($event) {
    $event?.stopPropagation();
    this.inputValue.set('');
    this.selectedOptions.set([]);
    this.isOpen.set(false);
    this.prevInputValue.set(null);
    this.cleared.emit();
    this.updateInputElValue();
  }
  updateInputElValue() {
    const inputEl = this.inputRefEl();
    const inputValue = this.inputValue();
    if (!inputEl) return;
    inputEl.value = inputValue;
    inputEl.dispatchEvent(new Event('input'));
  }
  #getProperty(obj, path) {
    return path.split('.').reduce((o, i) => o?.[i], obj);
  }
  #createCustomInputEventListener(input) {
    Object.defineProperty(input, 'value', {
      configurable: true,
      get() {
        const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(input), 'value');
        return descriptor.get.call(this);
      },
      set(newVal) {
        const descriptor = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(input), 'value');
        descriptor.set.call(this, newVal);
        const inputEvent = new CustomEvent('inputValueChanged', {
          bubbles: true,
          cancelable: true,
          detail: {
            value: newVal
          }
        });
        this.dispatchEvent(inputEvent);
        return newVal;
      }
    });
  }
  #createWildcardRegex(inputValue) {
    const lowerCaseInput = (inputValue ?? '').toLowerCase();
    let regexPattern = '^';
    for (const char of lowerCaseInput) {
      regexPattern += '.*' + this.#escapeRegexChar(char);
    }
    regexPattern += '.*$';
    return new RegExp(regexPattern, 'i');
  }
  #escapeRegexChar(char) {
    return char.replace(/[-\/\\^$+?.()|[\]{}]/g, '\\$&');
  }
  ngOnDestroy() {
    if (this.openAbortController) {
      this.openAbortController.abort();
      this.openAbortController = null;
    }
    if (typeof MutationObserver !== 'undefined') {
      this.#inputObserver.disconnect();
    }
  }
  static {
    this.ɵfac = function SparkleSelectComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleSelectComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleSelectComponent,
      selectors: [["spk-select"]],
      contentQueries: function SparkleSelectComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.inlineTemplate, TemplateRef, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      viewQuery: function SparkleSelectComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.optionsWrapRef, _c31, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      hostVars: 2,
      hostBindings: function SparkleSelectComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("multiple", ctx.selectMultiple());
        }
      },
      inputs: {
        value: [1, "value"],
        label: [1, "label"],
        asFreeText: [1, "asFreeText"],
        placeholder: [1, "placeholder"],
        readonly: [1, "readonly"],
        disabled: [1, "disabled"],
        lazySearch: [1, "lazySearch"],
        inlineSearch: [1, "inlineSearch"],
        asText: [1, "asText"],
        isClearable: [1, "isClearable"],
        selectMultiple: [1, "selectMultiple"],
        optionTemplate: [1, "optionTemplate"],
        selectedOptionTemplate: [1, "selectedOptionTemplate"],
        placeholderTemplate: [1, "placeholderTemplate"],
        isOpen: [1, "isOpen"],
        isLoading: [1, "isLoading"],
        options: [1, "options"],
        selectedOptions: [1, "selectedOptions"]
      },
      outputs: {
        readonly: "readonlyChange",
        disabled: "disabledChange",
        isOpen: "isOpenChange",
        isLoading: "isLoadingChange",
        options: "optionsChange",
        selectedOptions: "selectedOptionsChange",
        cleared: "cleared"
      },
      ngContentSelectors: _c33,
      decls: 32,
      vars: 20,
      consts: [["formFieldWrapper", ""], ["optionsWrap", ""], [3, "isOpenChange", "closed", "isOpen", "disableOpenByClick", "options"], ["trigger", "", 3, "click"], ["ngProjectAs", "input", 5, ["input"], 1, "input"], [1, "selected-value"], ["suffix", ""], ["suffix", "", 1, "primary"], [1, "sparkle-options"], [1, "option", 3, "selected", "focused"], [1, "small", 3, "class"], [1, "small"], [3, "click"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], [4, "ngTemplateOutlet"], ["suffix", "", 3, "click"], [1, "option", 3, "click"], [3, "class", "active"]],
      template: function SparkleSelectComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef(_c32);
          i0.ɵɵdeclareLet(0)(1)(2)(3)(4)(5)(6)(7)(8)(9);
          i0.ɵɵelementStart(10, "spk-popover", 2, 0);
          i0.ɵɵtwoWayListener("isOpenChange", function SparkleSelectComponent_Template_spk_popover_isOpenChange_10_listener($event) {
            i0.ɵɵrestoreView(_r1);
            i0.ɵɵtwoWayBindingSet(ctx.isOpen, $event) || (ctx.isOpen = $event);
            return i0.ɵɵresetView($event);
          });
          i0.ɵɵlistener("closed", function SparkleSelectComponent_Template_spk_popover_closed_10_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.close());
          });
          i0.ɵɵelementStart(12, "spk-form-field", 3);
          i0.ɵɵlistener("click", function SparkleSelectComponent_Template_spk_form_field_click_12_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.open());
          });
          i0.ɵɵprojection(13, 0, ["ngProjectAs", "label", 5, ["label"]]);
          i0.ɵɵelementStart(14, "div", 4)(15, "div", 5);
          i0.ɵɵtemplate(16, SparkleSelectComponent_Conditional_16_Template, 1, 1)(17, SparkleSelectComponent_Conditional_17_Template, 2, 0)(18, SparkleSelectComponent_Conditional_18_Template, 2, 1);
          i0.ɵɵprojection(19, 1);
          i0.ɵɵprojection(20, 2);
          i0.ɵɵelementEnd()();
          i0.ɵɵtemplate(21, SparkleSelectComponent_Conditional_21_Template, 2, 0, "spk-icon", 6)(22, SparkleSelectComponent_Conditional_22_Template, 1, 0, "spk-spinner", 7)(23, SparkleSelectComponent_Conditional_23_Template, 2, 0, "spk-icon", 6)(24, SparkleSelectComponent_Conditional_24_Template, 2, 0, "spk-icon", 6)(25, SparkleSelectComponent_Conditional_25_Template, 2, 0, "spk-icon", 6)(26, SparkleSelectComponent_Conditional_26_Template, 2, 0, "spk-icon", 6)(27, SparkleSelectComponent_Conditional_27_Template, 2, 0, "spk-icon", 6);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(28, "div", 8, 1);
          i0.ɵɵrepeaterCreate(30, SparkleSelectComponent_For_31_Template, 4, 6, "li", 9, i0.ɵɵrepeaterTrackByIndex);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵstoreLet(ctx.placeholderTemplate());
          const _optionTemplate_r16 = ctx.optionTemplate();
          const _selectedOptionTemplate_r17 = ctx.selectedOptionTemplate();
          const _inlineTemplate_r18 = ctx.inlineTemplate();
          i0.ɵɵadvance(4);
          const _selectedOptions_r19 = i0.ɵɵstoreLet(ctx.selectedOptions());
          const _inputState_r20 = ctx.inputState();
          i0.ɵɵadvance(2);
          i0.ɵɵstoreLet(_selectedOptionTemplate_r17 || _optionTemplate_r16 || _inlineTemplate_r18);
          i0.ɵɵadvance();
          i0.ɵɵstoreLet(_optionTemplate_r16 || _inlineTemplate_r18);
          i0.ɵɵadvance();
          const _asChips_r21 = i0.ɵɵstoreLet(!ctx.asText() && ctx.selectMultiple());
          i0.ɵɵadvance();
          const _showSearchText_r22 = i0.ɵɵstoreLet(ctx.isOpen() && ctx.hasSearch() && (_asChips_r21 || ctx.inputValue().length > 0));
          i0.ɵɵadvance();
          i0.ɵɵtwoWayProperty("isOpen", ctx.isOpen);
          i0.ɵɵproperty("disableOpenByClick", true)("options", i0.ɵɵpureFunction0(19, _c34));
          i0.ɵɵadvance(2);
          i0.ɵɵclassProp("autosize", ctx.selectMultiple())("readonly", ctx.readonly() || ctx.disabled());
          i0.ɵɵadvance(2);
          i0.ɵɵclassProp("show-search-text", _showSearchText_r22);
          i0.ɵɵadvance();
          i0.ɵɵclassProp("is-selected", _inputState_r20 === "selected");
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.asFreeText() && ctx.inputValue().length > 0 && !ctx.isOpen() ? 16 : _selectedOptions_r19.length > 0 ? 17 : !_showSearchText_r22 ? 18 : -1);
          i0.ɵɵadvance(5);
          i0.ɵɵconditional(_inputState_r20 === "closed" ? 21 : _inputState_r20 === "loading" ? 22 : _inputState_r20 === "open-searching" ? 23 : _inputState_r20 === "searching" ? 24 : _inputState_r20 === "selected" && ctx._isClearable() ? 25 : _inputState_r20 === "selected" && !ctx._isClearable() ? 26 : 27);
          i0.ɵɵadvance(9);
          i0.ɵɵrepeater(ctx.filteredOptions());
        }
      },
      dependencies: [NgTemplateOutlet, SparklePopoverComponent, SparkleFormFieldComponent, SparkleIconComponent, SparkleCheckboxComponent, SparkleSpinnerComponent, SparkleChipComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleSelectComponent, [{
    type: Component,
    args: [{
      selector: 'spk-select',
      imports: [NgTemplateOutlet, SparklePopoverComponent, SparkleFormFieldComponent, SparkleIconComponent, SparkleCheckboxComponent, SparkleSpinnerComponent, SparkleChipComponent],
      template: `
    @let _placeholderTemplate = placeholderTemplate();
    @let _optionTemplate = optionTemplate();
    @let _selectedOptionTemplate = selectedOptionTemplate();
    @let _inlineTemplate = inlineTemplate();
    @let _selectedOptions = selectedOptions();
    @let _inputState = inputState();

    @let _selOptionTemplate = _selectedOptionTemplate || _optionTemplate || _inlineTemplate;
    @let _listOptionTemplate = _optionTemplate || _inlineTemplate;
    @let _asChips = !asText() && selectMultiple();
    @let _showSearchText = isOpen() && hasSearch() && (_asChips || inputValue().length > 0);

    <spk-popover
      #formFieldWrapper
      [(isOpen)]="isOpen"
      [disableOpenByClick]="true"
      (closed)="close()"
      [options]="{
        closeOnButton: false,
        closeOnEsc: false,
      }">
      <spk-form-field
        trigger
        (click)="open()"
        [class.autosize]="selectMultiple()"
        [class.readonly]="readonly() || disabled()">
        <ng-content select="label" ngProjectAs="label" />

        <div class="input" [class.show-search-text]="_showSearchText" ngProjectAs="input">
          <div class="selected-value" [class.is-selected]="_inputState === 'selected'">
            @if (asFreeText() && inputValue().length > 0 && !isOpen()) {
              {{ inputValue() }}
            } @else if (_selectedOptions.length > 0) {
              @for (selectedOption of _selectedOptions; track $index) {
                @if (selectedOption) {
                  @if (_asChips) {
                    <spk-chip [class]="selectClasses()" class="small">
                      @if (_selOptionTemplate) {
                        <ng-container *ngTemplateOutlet="_selOptionTemplate; context: { $implicit: selectedOption }" />
                      } @else {
                        {{ getLabel(selectedOption) }}
                      }

                      <spk-icon (click)="removeSelectedOptionByIndex($event, $index)">x-bold</spk-icon>
                    </spk-chip>
                  } @else {
                    @if (!_showSearchText) {
                      @if (_selOptionTemplate) {
                        <ng-container *ngTemplateOutlet="_selOptionTemplate; context: { $implicit: selectedOption }" />
                      } @else {
                        {{ getLabel(selectedOption) }}
                      }
                    }
                  }
                }
              }
            } @else if (!_showSearchText) {
              @if (_placeholderTemplate) {
                <ng-container *ngTemplateOutlet="_placeholderTemplate" />
              } @else {
                {{ placeholderText() ?? '' }}
              }
            }

            <ng-content select="input" />
            <ng-content select="textarea" />
          </div>
        </div>

        @if (_inputState === 'closed') {
          <spk-icon suffix>caret-down</spk-icon>
        } @else if (_inputState === 'loading') {
          <spk-spinner class="primary" suffix></spk-spinner>
        } @else if (_inputState === 'open-searching') {
          <spk-icon suffix>list-magnifying-glass</spk-icon>
        } @else if (_inputState === 'searching') {
          <spk-icon suffix>magnifying-glass</spk-icon>
        } @else if (_inputState === 'selected' && _isClearable()) {
          <spk-icon suffix (click)="clear($event)">x-bold</spk-icon>
        } @else if (_inputState === 'selected' && !_isClearable()) {
          <spk-icon suffix>caret-down</spk-icon>
        } @else {
          <spk-icon suffix>caret-up</spk-icon>
        }
      </spk-form-field>

      <div class="sparkle-options" #optionsWrap>
        @for (option of filteredOptions(); track $index) {
          <li
            (click)="toggleOptionByIndex($index)"
            class="option"
            [class.selected]="isSelected($index)"
            [class.focused]="$index === focusedOptionIndex()">
            @if (selectMultiple()) {
              <spk-checkbox [class]="selectClasses()" [class.active]="isSelected($index)" />
            }

            @if (_listOptionTemplate) {
              <ng-container *ngTemplateOutlet="_listOptionTemplate; context: { $implicit: option }" />
            } @else {
              {{ getLabel(option) }}
            }
          </li>
        }
      </div>
    </spk-popover>
  `,
      host: {
        '[class.multiple]': 'selectMultiple()'
      },
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleSidenavComponent {
  constructor() {
    this.openWidth = 280;
    this.openWidthTreshold = this.openWidth * 0.9;
    this.disableDrag = input(false);
    this.isOpen = model(false);
    this.type = input('');
    this.dragImageElement = viewChild.required('dragImageElement');
    this.dragIsEnding = signal(false);
    this.dragIsOnScreen = signal(true);
    this.isDragging = signal(false);
    this.dragPositionX = signal(0);
    this.dragActualPositionX = computed(() => {
      const dragPosition = this.dragPositionX();
      const overdragFactor = 0.32;
      if (dragPosition > this.openWidthTreshold) {
        return this.openWidthTreshold + (dragPosition - this.openWidthTreshold) * overdragFactor;
      }
      return dragPosition;
    });
    this.draggingStyle = computed(() => {
      if (this.type() !== 'overlay') {
        return null;
      }
      if (this.isDragging()) {
        return `translateX(${this.dragActualPositionX()}px)`;
      }
      return this.isOpen() ? `translateX(${this.openWidth}px)` : `translateX(0px)`;
    });
    this.draggingEffect = effect(() => {
      // Disable scrolling when dragging
      if (this.isDragging()) {
        document.body.classList.add('dragging');
      }
    });
  }
  drop(e) {
    e.stopPropagation();
    this.#drop(e.clientX);
  }
  #drop(clientX) {
    this.isDragging.set(false);
    if (clientX <= 0) {
      this.isOpen.set(false);
    } else if (clientX > this.openWidthTreshold) {
      this.isOpen.set(true);
    } else if (clientX < this.openWidthTreshold) {
      if (!this.isOpen() && clientX < this.openWidthTreshold * 0.6) {
        this.isOpen.set(true);
      } else {
        this.isOpen.set(false);
      }
    }
  }
  dragEnd(e) {
    if (e.clientX < 0 || !this.dragIsOnScreen()) {
      this.#drop(0);
    }
  }
  dragEnter() {
    this.dragIsOnScreen.set(true);
  }
  dragLeave() {
    this.dragIsOnScreen.set(false);
  }
  dragOver(e) {
    e.preventDefault();
    this.#setDragImage(e);
  }
  dragStart(e) {
    e.stopPropagation();
    this.isDragging.set(true);
    this.#setDragImage(e);
  }
  #setDragImage(e) {
    e.dataTransfer?.setDragImage(this.dragImageElement().nativeElement, 0, 0);
  }
  drag(e) {
    e.stopPropagation();
    setTimeout(() => {
      this.isDragging() && this.dragIsOnScreen() && this.dragPositionX.set(e.clientX);
    });
    this.#setDragImage(e);
  }
  touchStart(e) {
    e.stopPropagation();
    this.isDragging.set(true);
  }
  touchMove(e) {
    e.stopPropagation();
    setTimeout(() => {
      this.isDragging() && this.dragIsOnScreen() && this.dragPositionX.set(e.touches[0].clientX);
    });
  }
  touchEnd(e) {
    e.stopPropagation();
    this.#drop(e.changedTouches[0].clientX);
  }
  touchCancel(e) {
    e.stopPropagation();
    this.isDragging.set(false);
  }
  static {
    this.ɵfac = function SparkleSidenavComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleSidenavComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleSidenavComponent,
      selectors: [["spk-sidenav"]],
      viewQuery: function SparkleSidenavComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.dragImageElement, _c36, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      hostVars: 10,
      hostBindings: function SparkleSidenavComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("open", ctx.isOpen())("closed", !ctx.isOpen())("overlay", ctx.type() === "overlay")("simple", ctx.type() === "simple")("is-dragging", ctx.isDragging());
        }
      },
      inputs: {
        disableDrag: [1, "disableDrag"],
        isOpen: [1, "isOpen"],
        type: [1, "type"]
      },
      outputs: {
        isOpen: "isOpenChange"
      },
      ngContentSelectors: _c38,
      decls: 10,
      vars: 5,
      consts: [["dragImageElement", ""], [1, "drag-image"], [1, "dropping-surface"], [1, "sidenav"], [1, "main-wrap"], ["draggable", "true", 1, "dragable"], [1, "closed-topbar"], [1, "dropping-surface", 3, "drop", "dragover", "dragenter", "dragleave"], ["draggable", "true", 1, "dragable", 3, "dragstart", "dragend", "drag", "touchstart", "touchmove", "touchend", "touchcancel"]],
      template: function SparkleSidenavComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c37);
          i0.ɵɵtemplate(0, SparkleSidenavComponent_Conditional_0_Template, 2, 0, "div", 1)(1, SparkleSidenavComponent_Conditional_1_Template, 1, 0, "div", 2);
          i0.ɵɵelementStart(2, "div", 3);
          i0.ɵɵprojection(3);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 4);
          i0.ɵɵtemplate(5, SparkleSidenavComponent_Conditional_5_Template, 1, 0, "div", 5);
          i0.ɵɵelementStart(6, "div", 6);
          i0.ɵɵprojection(7, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(8, "main");
          i0.ɵɵprojection(9, 2);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵconditional(ctx.type() === "overlay" ? 0 : -1);
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.type() === "overlay" && ctx.isDragging() ? 1 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵstyleProp("transform", ctx.draggingStyle());
          i0.ɵɵadvance();
          i0.ɵɵconditional(ctx.type() === "overlay" && !ctx.disableDrag() ? 5 : -1);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleSidenavComponent, [{
    type: Component,
    args: [{
      selector: 'spk-sidenav',
      template: `
    @if (type() === 'overlay') {
      <div #dragImageElement class="drag-image"></div>
    }

    @if (type() === 'overlay' && isDragging()) {
      <div
        class="dropping-surface"
        (drop)="drop($event)"
        (dragover)="dragOver($event)"
        (dragenter)="dragEnter()"
        (dragleave)="dragLeave()"></div>
    }

    <div class="sidenav">
      <ng-content select="[sidenav]"></ng-content>
    </div>

    <div class="main-wrap" [style.transform]="draggingStyle()">
      @if (type() === 'overlay' && !disableDrag()) {
        <div
          class="dragable"
          draggable="true"
          (dragstart)="dragStart($event)"
          (dragend)="dragEnd($event)"
          (drag)="drag($event)"
          (touchstart)="touchStart($event)"
          (touchmove)="touchMove($event)"
          (touchend)="touchEnd($event)"
          (touchcancel)="touchCancel($event)"></div>
      }

      <div class="closed-topbar">
        <ng-content select="[sidenav-closed-topbar]"></ng-content>
      </div>

      <main>
        <ng-content></ng-content>
      </main>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.open]': 'isOpen()',
        '[class.closed]': '!isOpen()',
        '[class.overlay]': 'type() === "overlay"',
        '[class.simple]': 'type() === "simple"',
        '[class.is-dragging]': 'isDragging()'
      }
    }]
  }], null, null);
})();
class GridSortableDirective {
  #selfRef = inject(ElementRef);
  #renderer = inject(Renderer2);
  #draggedItem = null;
  onDragStart(event) {
    this.#draggedItem = event.target;
    this.updateOrder(); // Initialize order for all children
  }
  onDragOver(event) {
    event.preventDefault();
  }
  onDragEnter(event) {
    const target = event.target;
    if (this.#draggedItem && target !== this.#draggedItem && target.parentNode === this.#draggedItem.parentNode) {
      const parent = this.#draggedItem.parentNode;
      const draggedIndex = Array.from(parent.children).indexOf(this.#draggedItem);
      const targetIndex = Array.from(parent.children).indexOf(target);
      // Update only the flipped indexes
      this.#renderer.setStyle(this.#draggedItem, 'order', targetIndex);
      this.#renderer.setStyle(target, 'order', draggedIndex);
    }
  }
  updateOrder(excludedItem = null) {
    const items = this.#selfRef.nativeElement.children;
    Array.from(items).forEach((item, index) => {
      if (item !== excludedItem) {
        this.#renderer.setStyle(item, 'order', index);
      }
    });
  }
  static {
    this.ɵfac = function GridSortableDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || GridSortableDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: GridSortableDirective,
      selectors: [["", "spkGridSortable", ""]],
      hostBindings: function GridSortableDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dragstart", function GridSortableDirective_dragstart_HostBindingHandler($event) {
            return ctx.onDragStart($event);
          })("dragover", function GridSortableDirective_dragover_HostBindingHandler($event) {
            return ctx.onDragOver($event);
          })("dragenter", function GridSortableDirective_dragenter_HostBindingHandler($event) {
            return ctx.onDragEnter($event);
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GridSortableDirective, [{
    type: Directive,
    args: [{
      standalone: true,
      selector: '[spkGridSortable]'
    }]
  }], null, {
    onDragStart: [{
      type: HostListener,
      args: ['dragstart', ['$event']]
    }],
    onDragOver: [{
      type: HostListener,
      args: ['dragover', ['$event']]
    }],
    onDragEnter: [{
      type: HostListener,
      args: ['dragenter', ['$event']]
    }]
  });
})();
class SparkleSortableComponent {
  constructor() {
    this.templateOne = contentChild.required('templateOne');
    this.templateTwo = contentChild.required('templateTwo');
    this.placeholderRef = viewChild.required('placeholderRef');
    this.items = model([]);
  }
  static {
    this.ɵfac = function SparkleSortableComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleSortableComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleSortableComponent,
      selectors: [["spk-sortable"]],
      contentQueries: function SparkleSortableComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.templateOne, _c39, 5);
          i0.ɵɵcontentQuerySignal(dirIndex, ctx.templateTwo, _c40, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance(2);
        }
      },
      viewQuery: function SparkleSortableComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.placeholderRef, _c41, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      inputs: {
        items: [1, "items"]
      },
      outputs: {
        items: "itemsChange"
      },
      decls: 4,
      vars: 0,
      consts: [["placeholderRef", ""], [1, "placeholder"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"]],
      template: function SparkleSortableComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelement(0, "div", 1, 0);
          i0.ɵɵrepeaterCreate(2, SparkleSortableComponent_For_3_Template, 2, 1, null, null, i0.ɵɵrepeaterTrackByIndex);
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵrepeater(ctx.items());
        }
      },
      dependencies: [NgTemplateOutlet],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleSortableComponent, [{
    type: Component,
    args: [{
      selector: 'spk-sortable',
      imports: [NgTemplateOutlet],
      template: `
    <div class="placeholder" #placeholderRef></div>

    @for (item of items(); track $index) {
      @if ($index === 0) {
        <ng-container *ngTemplateOutlet="templateOne(); context: { $implicit: item }"></ng-container>
      } @else {
        <ng-container *ngTemplateOutlet="templateTwo(); context: { $implicit: item }"></ng-container>
      }
    }
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleSortableDirective {
  constructor() {
    this.#selfEl = inject(ElementRef);
    this.#renderer = inject(Renderer2);
    this.#placeholderEl = signal(null);
    this.#parentGap = signal(0);
    this.dragStartIndex = signal(-1);
    this.dragToIndex = signal(-1);
    this.dragables = signal([]);
    this.afterDrop = output();
    this.abortController = null;
    this.draggingEffect = effect(() => {
      const currentDragPosIndex = this.dragToIndex();
      const startIndex = this.dragStartIndex();
      if (currentDragPosIndex > -1 && startIndex > -1) {
        const dragables = this.dragables();
        const placeholderEl = this.#placeholderEl();
        const gapValue = this.#parentGap();
        const draggedElement = dragables[startIndex];
        const totalShift = draggedElement.offsetHeight + gapValue;
        let placeholderElShift = 0;
        if (currentDragPosIndex > startIndex) {
          for (let i = startIndex + 1; i <= currentDragPosIndex; i++) {
            placeholderElShift += dragables[i].offsetHeight + gapValue;
          }
        } else if (currentDragPosIndex < startIndex) {
          for (let i = startIndex - 1; i >= currentDragPosIndex; i--) {
            placeholderElShift -= dragables[i].offsetHeight + gapValue;
          }
        }
        if (placeholderEl) {
          const newTransform = `translateY(${placeholderElShift}px)`;
          if (placeholderEl.style.transform !== newTransform) {
            this.#renderer.setStyle(placeholderEl, 'transform', newTransform);
          }
        }
        for (let i = 0; i < dragables.length; i++) {
          if (i === startIndex || i === dragables.length - 1) continue;
          let newTransform = 'translateY(0)';
          if (currentDragPosIndex > startIndex && currentDragPosIndex >= i && startIndex < i) {
            newTransform = `translateY(${-totalShift}px)`;
          } else if (currentDragPosIndex < startIndex && currentDragPosIndex <= i && startIndex > i) {
            newTransform = `translateY(${totalShift}px)`;
          }
          if (dragables[i].style.transform !== newTransform) {
            this.#renderer.setStyle(dragables[i], 'transform', newTransform);
          }
        }
      }
    });
    this.draggablesEffect = effect(() => {
      const els = this.dragables();
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      for (let index = 0; index < els.length; index++) {
        els[index].addEventListener('dragstart', e => this.dragStart(e), {
          signal: this.abortController.signal
        });
        els[index].addEventListener('dragenter', e => this.dragEnter(e), {
          signal: this.abortController.signal,
          capture: true
        });
      }
    });
    this.#dragableObserver = typeof MutationObserver !== 'undefined' && new MutationObserver(mutations => {
      for (var mutation of mutations) {
        if (mutation.type == 'childList') {
          this.dragables.set(Array.from(this.#selfEl.nativeElement.querySelectorAll('[draggable]')));
        }
      }
    });
  }
  #selfEl;
  #renderer;
  #placeholderEl;
  #parentGap;
  getIndexOfElement(element) {
    return this.dragables().findIndex(el => el == element);
  }
  dragEnter(e) {
    const draggableAncestor = e.target.closest('[draggable]');
    if (draggableAncestor && !draggableAncestor.classList.contains('sortable-placeholder')) {
      this.dragToIndex.set(this.getIndexOfElement(draggableAncestor));
    }
  }
  dragStart(e) {
    if (e.target) {
      const targetElement = e.target;
      const currentTarget = document.elementFromPoint(e.clientX, e.clientY);
      const isSortingHandle = currentTarget?.hasAttribute('sort-handle') || currentTarget?.closest('[sort-handle]') !== null;
      let draggedElement;
      if (isSortingHandle) {
        draggedElement = targetElement.closest('[draggable]');
      } else {
        draggedElement = targetElement;
        if (draggedElement.parentElement?.querySelector('[sort-handle]') !== null || !draggedElement.draggable) {
          e.preventDefault();
          return;
        }
      }
      const parentStyle = window.getComputedStyle(draggedElement.parentElement);
      this.#parentGap.set(parseFloat(parentStyle.gap) || 0);
      const draggedElementIndex = this.getIndexOfElement(draggedElement);
      this.dragStartIndex.set(draggedElementIndex);
      this.#renderer.setStyle(this.dragables()[draggedElementIndex], 'opacity', '0');
      this.#renderer.setStyle(this.dragables()[draggedElementIndex], 'zIndex', '2');
      this.#renderer.addClass(this.#selfEl.nativeElement, 'dragging');
      setTimeout(() => {
        const placeholderElement = draggedElement.cloneNode(true);
        this.#renderer.addClass(placeholderElement, 'sortable-placeholder');
        this.#renderer.setStyle(placeholderElement, 'left', `${draggedElement.offsetLeft}px`);
        this.#renderer.setStyle(placeholderElement, 'width', `${draggedElement.offsetWidth}px`);
        this.#renderer.setStyle(placeholderElement, 'top', `${draggedElement.offsetTop}px`);
        this.#renderer.setStyle(placeholderElement, 'zIndex', '1');
        this.#renderer.setStyle(placeholderElement, 'opacity', '.4');
        this.#placeholderEl.set(placeholderElement);
        this.#selfEl.nativeElement.appendChild(placeholderElement);
      });
    }
  }
  ngOnInit() {
    if (typeof MutationObserver !== 'undefined') {
      this.#dragableObserver.observe(this.#selfEl.nativeElement, {
        childList: true,
        subtree: false
      });
    }
  }
  dragOver(e) {
    e.preventDefault();
  }
  drop() {
    this.#resetStyles();
    this.afterDrop.emit({
      fromIndex: this.dragStartIndex(),
      toIndex: this.dragToIndex()
    });
    this.dragStartIndex.set(-1);
    this.dragToIndex.set(-1);
    this.#selfEl.nativeElement.classList.remove('dragging');
    if (this.#placeholderEl()) {
      this.#selfEl.nativeElement.removeChild(this.#placeholderEl());
    }
  }
  #resetStyles() {
    const dragables = this.dragables();
    for (let i = 0; i < dragables.length; i++) {
      const el = dragables[i];
      this.#renderer.setStyle(el, 'transform', '');
      this.#renderer.setStyle(el, 'opacity', '1');
      this.#renderer.setStyle(el, 'zIndex', '1');
    }
  }
  #dragableObserver;
  ngOnDestroy() {
    if (typeof MutationObserver !== 'undefined') {
      this.#dragableObserver.disconnect();
    }
    if (this.abortController) {
      this.abortController.abort();
    }
  }
  static {
    this.ɵfac = function SparkleSortableDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleSortableDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SparkleSortableDirective,
      selectors: [["", "spkSortable", ""]],
      hostBindings: function SparkleSortableDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dragover", function SparkleSortableDirective_dragover_HostBindingHandler($event) {
            return ctx.dragOver($event);
          })("drop", function SparkleSortableDirective_drop_HostBindingHandler() {
            return ctx.drop();
          });
        }
      },
      outputs: {
        afterDrop: "afterDrop"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleSortableDirective, [{
    type: Directive,
    args: [{
      selector: '[spkSortable]',
      standalone: true
    }]
  }], null, {
    dragOver: [{
      type: HostListener,
      args: ['dragover', ['$event']]
    }],
    drop: [{
      type: HostListener,
      args: ['drop']
    }]
  });
})();
function moveIndex(array, event) {
  const {
    fromIndex,
    toIndex
  } = event;
  if (fromIndex < 0 || fromIndex >= array.length || toIndex < 0 || toIndex >= array.length) {
    return array;
  }
  const newArray = [...array];
  const [removedItem] = newArray.splice(fromIndex, 1);
  newArray.splice(toIndex, 0, removedItem);
  return newArray;
}
class SparkleStepperComponent {
  static {
    this.ɵfac = function SparkleStepperComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleStepperComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleStepperComponent,
      selectors: [["spk-stepper"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleStepperComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleStepperComponent, [{
    type: Component,
    args: [{
      selector: 'spk-stepper',
      imports: [],
      template: `
    <ng-content></ng-content>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
function observeFirstChild(parentEl, elementTags) {
  const elementSignal = signal(null);
  const _upperCaseElementTags = elementTags.map(tag => tag.toUpperCase());
  const injector = inject(Injector);
  const destroyRef = injector.get(DestroyRef);
  if (typeof MutationObserver === 'undefined') return elementSignal.asReadonly();
  const initialElement = _upperCaseElementTags.find(tag => parentEl.nativeElement.querySelector(tag));
  if (initialElement) {
    elementSignal.set(new ElementRef(parentEl.nativeElement.querySelector(elementTags[0])));
    return elementSignal.asReadonly();
  }
  const observer = new MutationObserver(mutations => {
    for (const mutation of mutations) {
      if (mutation.addedNodes) {
        for (const node of Array.from(mutation.addedNodes)) {
          if (node.nodeType === Node.ELEMENT_NODE && _upperCaseElementTags.includes(node.nodeName)) {
            elementSignal.set(new ElementRef(node));
            observer.disconnect();
            return;
          }
        }
      }
    }
  });
  observer.observe(parentEl.nativeElement, {
    childList: true,
    subtree: true
  });
  destroyRef.onDestroy(() => observer.disconnect());
  return elementSignal.asReadonly();
}
function observeChildren(parentEl, elementTags) {
  const injector = inject(Injector);
  const destroyRef = injector.get(DestroyRef);
  const elementsSignal = signal([]);
  const _upperCaseElementTags = elementTags.map(tag => tag.toUpperCase());
  let observer = null;
  const setupObserver = el => {
    if (observer) {
      observer.disconnect();
    }
    const foundElements = Array.from(el.nativeElement.querySelectorAll('*')).filter(elem => elem.nodeType === Node.ELEMENT_NODE && _upperCaseElementTags.includes(elem.nodeName)).map(elem => new ElementRef(elem));
    elementsSignal.set(foundElements);
    observer = new MutationObserver(mutations => {
      let currentElements = elementsSignal();
      for (const mutation of mutations) {
        if (mutation.addedNodes) {
          mutation.addedNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE && _upperCaseElementTags.includes(node.nodeName)) {
              currentElements = [...currentElements, new ElementRef(node)];
            }
          });
        }
        if (mutation.removedNodes) {
          mutation.removedNodes.forEach(node => {
            if (node.nodeType === Node.ELEMENT_NODE && _upperCaseElementTags.includes(node.nodeName)) {
              currentElements = currentElements.filter(elem => elem.nativeElement !== node);
            }
          });
        }
      }
      elementsSignal.set(currentElements);
    });
    observer.observe(el.nativeElement, {
      childList: true,
      subtree: true
    });
    destroyRef.onDestroy(() => {
      if (observer) {
        observer.disconnect();
      }
    });
  };
  if (typeof parentEl === 'function' && !!parentEl[SIGNAL]) {
    effect(() => {
      const el = parentEl();
      if (el) {
        setupObserver(el);
      } else {
        elementsSignal.set([]);
        if (observer) {
          observer.disconnect();
          observer = null;
        }
      }
    }, {
      injector
    });
  } else {
    if (parentEl instanceof ElementRef) {
      setupObserver(parentEl);
    }
  }
  return elementsSignal.asReadonly();
}
class SparkleResizeDirective {
  constructor() {
    this.#el = inject(ElementRef);
    this.#renderer = inject(Renderer2);
    this.#table = inject(SparkleTableComponent);
    this.resizable = input(true);
    this.minWidth = input(50);
    this.maxWidth = input(null);
    this.#resizing = false;
    this.#animationFrameRequest = null; // Store request ID
  }
  #el;
  #renderer;
  #table;
  #startX;
  #startWidth;
  #resizing;
  #animationFrameRequest; // Store request ID
  ngOnInit() {
    if (!this.#table) {
      console.error('spkTableResize directive must be used within a spk-table component.');
      return;
    }
    if (this.resizable()) {
      const resizer = this.#renderer.createElement('div');
      this.#renderer.addClass(resizer, 'spk-resizer');
      this.#renderer.appendChild(this.#el.nativeElement, resizer);
      this.#renderer.listen(resizer, 'mousedown', this.#onMouseDown.bind(this));
    }
  }
  onMouseMove(event) {
    if (!this.#resizing) return;
    this.#scheduleResize(event);
  }
  onMouseUp(event) {
    if (this.#resizing) {
      this.#resizing = false;
      this.#table.resizing.set(false);
      if (this.#animationFrameRequest !== null) {
        cancelAnimationFrame(this.#animationFrameRequest);
        this.#animationFrameRequest = null;
      }
    }
  }
  onClick(event) {
    if (this.#resizing) {
      event.stopPropagation();
    }
  }
  #onMouseDown(event) {
    event.stopPropagation();
    if (!this.resizable()) return;
    this.#table.resizing.set(true);
    this.#resizing = true;
    this.#startX = event.pageX;
    this.#startWidth = this.#el.nativeElement.offsetWidth;
  }
  #scheduleResize(event) {
    if (this.#animationFrameRequest !== null) {
      cancelAnimationFrame(this.#animationFrameRequest);
    }
    this.#animationFrameRequest = requestAnimationFrame(() => {
      this.#resizeColumn(event);
      this.#animationFrameRequest = null;
    });
  }
  #resizeColumn(event) {
    const width = this.#startWidth + (event.pageX - this.#startX);
    const constrainedWidth = Math.max(this.minWidth(), this.maxWidth() ? Math.min(width, this.maxWidth() ?? width) : width);
    this.#renderer.setAttribute(this.#el.nativeElement, 'data-size', `${constrainedWidth}px`);
    this.#table.updateColumnSizes();
  }
  ngOnDestroy() {
    if (this.#animationFrameRequest !== null) {
      cancelAnimationFrame(this.#animationFrameRequest);
    }
  }
  static {
    this.ɵfac = function SparkleResizeDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleResizeDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SparkleResizeDirective,
      selectors: [["", "spkResize", ""]],
      hostBindings: function SparkleResizeDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mousemove", function SparkleResizeDirective_mousemove_HostBindingHandler($event) {
            return ctx.onMouseMove($event);
          }, false, i0.ɵɵresolveDocument)("mouseup", function SparkleResizeDirective_mouseup_HostBindingHandler($event) {
            return ctx.onMouseUp($event);
          }, false, i0.ɵɵresolveDocument)("click", function SparkleResizeDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          }, false, i0.ɵɵresolveDocument);
        }
      },
      inputs: {
        resizable: [1, "resizable"],
        minWidth: [1, "minWidth"],
        maxWidth: [1, "maxWidth"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleResizeDirective, [{
    type: Directive,
    args: [{
      selector: '[spkResize]',
      standalone: true
    }]
  }], null, {
    onMouseMove: [{
      type: HostListener,
      args: ['document:mousemove', ['$event']]
    }],
    onMouseUp: [{
      type: HostListener,
      args: ['document:mouseup', ['$event']]
    }],
    onClick: [{
      type: HostListener,
      args: ['document:click', ['$event']]
    }]
  });
})();
class SparkleSortDirective {
  constructor() {
    this.#table = inject(SparkleTableComponent);
    this.spkSort = input();
  }
  #table;
  toggleSort() {
    const sortCol = this.spkSort();
    if (!sortCol) return;
    this.#table.toggleSort(sortCol);
  }
  static {
    this.ɵfac = function SparkleSortDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleSortDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SparkleSortDirective,
      selectors: [["", "spkSort", ""]],
      hostBindings: function SparkleSortDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("mousedown", function SparkleSortDirective_mousedown_HostBindingHandler() {
            return ctx.toggleSort();
          });
        }
      },
      inputs: {
        spkSort: [1, "spkSort"]
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleSortDirective, [{
    type: Directive,
    args: [{
      selector: '[spkSort]',
      standalone: true,
      host: {
        '(mousedown)': 'toggleSort()'
      }
    }]
  }], null, null);
})();
class SparkleTableComponent {
  constructor() {
    this.loading = input(false);
    this.data = input([]);
    this.dataChange = output();
    this.sortByColumn = model(null);
    this.thead = viewChild('thead');
    this.columns = observeChildren(this.thead, ['TH']);
    this.resizing = signal(false);
    this.sizeTrigger = signal(true);
    this.#initialData = null;
    this.#initialDataSet = signal(false);
    this.columnSizes = computed(() => {
      this.sizeTrigger();
      return this.columns().reduce((acc, col, index) => {
        const colEl = col.nativeElement;
        const last = index === this.columns().length - 1;
        if (colEl.dataset['size']) {
          return `${acc} ${colEl.dataset['size']}`;
        }
        if (last) {
          return `${acc} max-content`;
        }
        return `${acc} 1fr`;
      }, '');
    });
    this.e = effect(() => {
      const sortByColumn = this.sortByColumn();
      if (sortByColumn === null) {
        if (!this.#initialDataSet()) {
          this.#initialData = this.data();
          this.#initialDataSet.set(true);
        }
        return this.dataChange.emit(JSON.parse(JSON.stringify(this.#initialData)));
      }
      const column = sortByColumn.startsWith('-') ? sortByColumn.slice(1) : sortByColumn;
      const isDescending = sortByColumn.startsWith('-');
      const sortedData = this.data().sort((a, b) => {
        const valueA = a[column];
        const valueB = b[column];
        let comparison = 0;
        if (typeof valueA === 'number' && typeof valueB === 'number') {
          comparison = valueA - valueB;
        }
        if (valueA instanceof Date && valueB instanceof Date) {
          comparison = valueA.getTime() - valueB.getTime();
        }
        if (typeof valueA === 'string' && typeof valueB === 'string') {
          comparison = valueA.localeCompare(valueB, undefined, {
            sensitivity: 'base'
          });
        }
        return isDescending ? -comparison : comparison;
      });
      this.dataChange.emit(sortedData);
    });
  }
  #initialData;
  #initialDataSet;
  updateColumnSizes() {
    this.sizeTrigger.set(!this.sizeTrigger());
  }
  toggleSort(column) {
    const currentSort = this.sortByColumn();
    const sortDir = currentSort === column ? `-${column}` : currentSort === `-${column}` ? null : column;
    this.sortByColumn.set(sortDir);
  }
  static {
    this.ɵfac = function SparkleTableComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleTableComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleTableComponent,
      selectors: [["spk-table"]],
      viewQuery: function SparkleTableComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.thead, _c42, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance();
        }
      },
      hostVars: 4,
      hostBindings: function SparkleTableComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("grid-template-columns", ctx.columnSizes());
          i0.ɵɵclassProp("resizing", ctx.resizing());
        }
      },
      inputs: {
        loading: [1, "loading"],
        data: [1, "data"],
        sortByColumn: [1, "sortByColumn"]
      },
      outputs: {
        dataChange: "dataChange",
        sortByColumn: "sortByColumnChange"
      },
      ngContentSelectors: _c44,
      decls: 7,
      vars: 2,
      consts: [["thead", ""], [1, "indeterminate", "primary"], [1, "no-rows"]],
      template: function SparkleTableComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c43);
          i0.ɵɵelementStart(0, "thead", null, 0);
          i0.ɵɵprojection(2);
          i0.ɵɵtemplate(3, SparkleTableComponent_Conditional_3_Template, 1, 0, "spk-progress-bar", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "tbody");
          i0.ɵɵprojection(5, 1);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(6, SparkleTableComponent_Conditional_6_Template, 2, 0, "div", 2);
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(ctx.loading() ? 3 : -1);
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(!ctx.loading() ? 6 : -1);
        }
      },
      dependencies: [SparkleProgressBarComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleTableComponent, [{
    type: Component,
    args: [{
      selector: 'spk-table',
      imports: [SparkleProgressBarComponent],
      template: `
    <thead #thead>
      <ng-content select="[table-header]" />

      @if (loading()) {
        <spk-progress-bar class="indeterminate primary" />
      }
    </thead>

    <tbody>
      <ng-content />
    </tbody>

    @if (!loading()) {
      <div class="no-rows">
        <ng-content select="[table-no-rows]" />
      </div>
    }
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[style.grid-template-columns]': 'columnSizes()',
        '[class.resizing]': 'resizing()'
      }
    }]
  }], null, null);
})();
class SparkleTabsComponent {
  static {
    this.ɵfac = function SparkleTabsComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleTabsComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleTabsComponent,
      selectors: [["spk-tabs"]],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function SparkleTabsComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleTabsComponent, [{
    type: Component,
    args: [{
      selector: 'spk-tabs',
      imports: [],
      template: `
    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleToggleCardComponent {
  constructor() {
    this.isActive = model(false);
    this.disallowToggle = input(false);
  }
  ngOnInit() {
    if (this.disallowToggle()) {
      this.isActive.set(true);
    }
  }
  toggle() {
    this.isActive.set(!this.isActive());
  }
  static {
    this.ɵfac = function SparkleToggleCardComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleToggleCardComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleToggleCardComponent,
      selectors: [["spk-toggle-card"]],
      hostVars: 2,
      hostBindings: function SparkleToggleCardComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("active", ctx.isActive());
        }
      },
      inputs: {
        isActive: [1, "isActive"],
        disallowToggle: [1, "disallowToggle"]
      },
      outputs: {
        isActive: "isActiveChange"
      },
      ngContentSelectors: _c46,
      decls: 6,
      vars: 1,
      consts: [[3, "click"], [1, "content"]],
      template: function SparkleToggleCardComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef(_c45);
          i0.ɵɵelementStart(0, "h3", 0);
          i0.ɵɵlistener("click", function SparkleToggleCardComponent_Template_h3_click_0_listener() {
            return ctx.disallowToggle() || ctx.toggle();
          });
          i0.ɵɵprojection(1, 0, null, SparkleToggleCardComponent_ProjectionFallback_1_Template, 1, 0);
          i0.ɵɵtemplate(3, SparkleToggleCardComponent_Conditional_3_Template, 2, 0, "spk-icon");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(4, "div", 1);
          i0.ɵɵprojection(5, 1);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵadvance(3);
          i0.ɵɵconditional(!ctx.disallowToggle() ? 3 : -1);
        }
      },
      dependencies: [SparkleIconComponent],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleToggleCardComponent, [{
    type: Component,
    args: [{
      selector: 'spk-toggle-card',
      imports: [SparkleIconComponent],
      template: `
    <h3 (click)="disallowToggle() || toggle()">
      <ng-content select="[title]">Title</ng-content>

      @if (!disallowToggle()) {
        <spk-icon>caret-down</spk-icon>
      }
    </h3>

    <div class="content">
      <ng-content></ng-content>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.active]': 'isActive()'
      }
    }]
  }], null, null);
})();
class SparkleToggleComponent {
  static {
    this.ɵfac = function SparkleToggleComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleToggleComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleToggleComponent,
      selectors: [["spk-toggle"]],
      ngContentSelectors: _c0,
      decls: 3,
      vars: 0,
      consts: [[1, "box"], [1, "knob"]],
      template: function SparkleToggleComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵelement(1, "div", 1);
          i0.ɵɵelementEnd();
          i0.ɵɵprojection(2);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleToggleComponent, [{
    type: Component,
    args: [{
      selector: 'spk-toggle',
      imports: [],
      template: `
    <div class="box">
      <div class="knob"></div>
    </div>

    <ng-content />
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleTooltipComponent {
  constructor() {
    this.#BASE_SPACE = 4;
    this.SUPPORTS_ANCHOR = CSS.supports('position-anchor', '--abc') && CSS.supports('anchor-name', '--abc');
    this.disabled = input(false);
    this.above = input(false);
    this.right = input(false);
    this._above = signal(this.above());
    this._right = signal(this.right());
    this.message = input.required();
    this.isOpen = model(false);
    this.closed = output();
    this.triggerRef = viewChild.required('triggerRef');
    this.tooltipRef = viewChild.required('tooltipRef');
    this.id = signal('--' + generateUniqueId());
    this.menuStyle = signal(null);
    this.isCalculatingPosition = computed(() => {
      const tooltipEl = this.tooltipRef()?.nativeElement;
      const open = this.isOpen();
      if (open) {
        tooltipEl?.showPopover();
        return true;
      } else {
        tooltipEl.hidePopover();
        this.abortController?.abort();
        this.closed.emit();
        return false;
      }
    });
    this.abortController = null;
    this.calcPositionEffect = effect(() => {
      const isCalculatingPosition = this.isCalculatingPosition();
      if (!isCalculatingPosition) return;
      if (this.abortController) {
        this.abortController.abort();
      }
      this.abortController = new AbortController();
      const signal = this.abortController.signal;
      this.calculateMenuPosition();
      const scrollableParent = this.#findScrollableParent(this.tooltipRef()?.nativeElement);
      scrollableParent.addEventListener('scroll', () => this.calculateMenuPosition(), {
        signal
      });
      document.addEventListener('resize', () => this.calculateMenuPosition(), {
        signal
      });
    });
    this.scrollableStyles = ['scroll', 'auto'];
  }
  #BASE_SPACE;
  #findScrollableParent(element) {
    let parent = element.parentElement;
    while (parent) {
      if (this.scrollableStyles.indexOf(window.getComputedStyle(parent).overflowY) > -1 && parent.scrollHeight > parent.clientHeight) {
        return parent;
      }
      parent = parent.parentElement;
    }
    return document.documentElement;
  }
  eventClose($event) {
    $event.stopPropagation();
    $event.preventDefault();
    this.isOpen.set(false);
  }
  ngOnDestroy() {
    if (this.abortController) {
      this.abortController.abort();
    }
  }
  calculateMenuPosition() {
    const triggerRect = this.triggerRef()?.nativeElement.getBoundingClientRect();
    const menuRect = this.tooltipRef()?.nativeElement.getBoundingClientRect();
    const actionLeftInViewport = triggerRect.left;
    const actionBottomInViewport = triggerRect.bottom;
    let newLeft = actionLeftInViewport;
    let newTop = actionBottomInViewport + this.#BASE_SPACE;
    const outOfBoundsRight = newLeft + menuRect.width > window.innerWidth;
    const outOfBoundsBottom = newTop + menuRect.height > window.innerHeight;
    if (this.SUPPORTS_ANCHOR) {
      this._above.set(outOfBoundsBottom);
      this._right.set(outOfBoundsRight);
    } else {
      if (this.above()) {
        const _newTop = triggerRect.top - menuRect.height - this.#BASE_SPACE;
        if (_newTop >= 0) {
          newTop = _newTop;
        }
      } else {
        if (outOfBoundsBottom) {
          newTop = triggerRect.top - menuRect.height - this.#BASE_SPACE;
        }
      }
      if (this.right()) {
        const _newLeft = triggerRect.right - menuRect.width;
        if (_newLeft >= 0) {
          newLeft = _newLeft;
        }
      } else {
        if (outOfBoundsRight) {
          newTop = outOfBoundsBottom ? triggerRect.top + triggerRect.height - menuRect.height : triggerRect.top;
          newLeft = triggerRect.left - menuRect.width - this.#BASE_SPACE;
        }
      }
      this.menuStyle.set({
        left: newLeft + 'px',
        top: newTop + 'px'
      });
    }
  }
  static {
    this.ɵfac = function SparkleTooltipComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleTooltipComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleTooltipComponent,
      selectors: [["spk-tooltip"]],
      viewQuery: function SparkleTooltipComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.triggerRef, _c8, 5);
          i0.ɵɵviewQuerySignal(ctx.tooltipRef, _c47, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance(2);
        }
      },
      hostVars: 6,
      hostBindings: function SparkleTooltipComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵclassProp("above", ctx._above())("right", ctx._right())("disabled", ctx.disabled());
        }
      },
      inputs: {
        disabled: [1, "disabled"],
        above: [1, "above"],
        right: [1, "right"],
        message: [1, "message"],
        isOpen: [1, "isOpen"]
      },
      outputs: {
        isOpen: "isOpenChange",
        closed: "closed"
      },
      ngContentSelectors: _c0,
      decls: 6,
      vars: 7,
      consts: [["triggerRef", ""], ["tooltipRef", ""], [1, "trigger", 3, "mouseover", "mouseout"], ["popover", "", 1, "tooltip"]],
      template: function SparkleTooltipComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 2, 0);
          i0.ɵɵlistener("mouseover", function SparkleTooltipComponent_Template_div_mouseover_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.isOpen.set(true));
          })("mouseout", function SparkleTooltipComponent_Template_div_mouseout_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.isOpen.set(false));
          });
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "div", 3, 1);
          i0.ɵɵtext(5);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵstyleProp("anchor-name", ctx.id());
          i0.ɵɵadvance(3);
          i0.ɵɵstyleMap(ctx.menuStyle());
          i0.ɵɵstyleProp("position-anchor", ctx.id());
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" ", ctx.message(), " ");
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleTooltipComponent, [{
    type: Component,
    args: [{
      selector: 'spk-tooltip',
      imports: [],
      template: `
    <div
      class="trigger"
      #triggerRef
      [style.anchor-name]="id()"
      (mouseover)="isOpen.set(true)"
      (mouseout)="isOpen.set(false)">
      <ng-content />
    </div>

    <div class="tooltip" #tooltipRef [style.position-anchor]="id()" [style]="menuStyle()" popover>
      {{ message() }}
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush,
      host: {
        '[class.above]': '_above()',
        '[class.right]': '_right()',
        '[class.disabled]': 'disabled()'
      }
    }]
  }], null, null);
})();
class SparkleVirtualScrollComponent {
  constructor() {
    this.#changeRef = inject(ChangeDetectorRef);
    this.#renderer = inject(Renderer2);
    this.#hostElement = inject(ElementRef);
    this.viewportRef = viewChild.required('viewport');
    this.itemElements = viewChildren('item');
    this.bufferSize = signal(10);
    this.itemHeights = signal([]);
    this.startIndex = signal(0);
    this.endIndex = signal(0);
    this.translateY = signal(0);
    this.totalHeight = computed(() => this.itemHeights().reduce((sum, height) => sum + height, 0));
    this.numberOfRenderedItems = signal(0);
    this.#resizeObserver = null;
    this.#hostResizeObserver = null;
    this.#itemHeightsEffect = effect(() => {
      const startIndex = this.startIndex();
      const endIndex = this.endIndex();
      const itemHeights = this.itemHeights();
      if (startIndex > 0 && endIndex > 0 && itemHeights.length > 0) {
        let newTranslateY = 0;
        for (let i = 0; i < startIndex; i++) {
          newTranslateY += itemHeights[i];
        }
        this.translateY.set(newTranslateY);
      }
    });
    this.#totalHeightEffect = effect(() => {
      const _ = this.totalHeight();
      this.#changeRef.detectChanges();
    });
    this.#itemElementsEffect = effect(() => {
      const itemElements = this.itemElements();
      if (this.#resizeObserver && itemElements) {
        this.#resizeObserver.disconnect();
        const heights = new Array(itemElements.length).fill(0);
        this.numberOfRenderedItems.set(itemElements.length);
        this.itemHeights.set(heights);
        for (let i = 0; i < itemElements.length; i++) {
          this.#resizeObserver.observe(itemElements[i].nativeElement);
          heights[i] = itemElements[i].nativeElement.offsetHeight;
        }
        this.itemHeights.set(heights);
        this.#calculateVisibleItems();
      }
    });
  }
  #changeRef;
  #renderer;
  #hostElement;
  #resizeObserver;
  #hostResizeObserver;
  #itemHeightsEffect;
  #totalHeightEffect;
  #itemElementsEffect;
  ngAfterViewInit() {
    this.#setupHostResizeObserver();
    this.#setupResizeObserver();
    this.#changeRef.detectChanges();
  }
  onScroll() {
    this.#calculateVisibleItems();
  }
  #setupHostResizeObserver() {
    this.#hostResizeObserver = new ResizeObserver(entries => {
      const hostElement = entries[0];
      if (hostElement) {
        const newHeight = hostElement.contentRect.height;
        this.#renderer.setStyle(this.viewportRef().nativeElement, 'height', `${newHeight}px`);
      }
    });
    this.#hostResizeObserver.observe(this.#hostElement.nativeElement);
  }
  #setupResizeObserver() {
    this.#resizeObserver = new ResizeObserver(entries => {
      const newHeights = [...this.itemHeights()];
      let didUpdate = false;
      for (const entry of entries) {
        const index = this.itemElements().findIndex(el => el.nativeElement === entry.target);
        if (index !== undefined && index !== -1) {
          const newHeight = entry.contentRect.height;
          if (newHeights[index] !== newHeight) {
            newHeights[index] = newHeight;
            didUpdate = true;
          }
        }
      }
      if (didUpdate) {
        this.itemHeights.set(newHeights);
        this.#calculateVisibleItems();
      }
    });
  }
  #cleanupResizeObserver() {
    if (this.#resizeObserver) {
      this.#resizeObserver.disconnect();
      this.#resizeObserver = null;
    }
  }
  #cleanupHostResizeObserver() {
    if (this.#hostResizeObserver) {
      this.#hostResizeObserver.disconnect();
      this.#hostResizeObserver = null;
    }
  }
  #calculateVisibleItems() {
    const nativeElement = this.viewportRef();
    if (!nativeElement) return;
    const scrollTop = nativeElement.nativeElement.scrollTop;
    const viewportHeight = nativeElement.nativeElement.clientHeight;
    let accumulatedHeight = 0;
    let startIndex = -1;
    let endIndex = -1;
    for (let i = 0; i < this.itemHeights().length; i++) {
      const itemHeight = this.itemHeights()[i];
      if (startIndex === -1 && accumulatedHeight + itemHeight >= scrollTop - this.bufferSize()) {
        startIndex = i;
      }
      if (endIndex === -1 && accumulatedHeight >= scrollTop + viewportHeight + this.bufferSize()) {
        endIndex = i;
        break;
      }
      accumulatedHeight += itemHeight;
    }
    if (endIndex === -1) {
      endIndex = this.itemHeights().length - 1;
    }
    if (this.startIndex() !== startIndex) {
      this.startIndex.set(startIndex);
    }
    if (this.endIndex() !== endIndex) {
      this.endIndex.set(endIndex);
    }
  }
  ngOnDestroy() {
    this.#cleanupResizeObserver();
    this.#cleanupHostResizeObserver();
  }
  static {
    this.ɵfac = function SparkleVirtualScrollComponent_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleVirtualScrollComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SparkleVirtualScrollComponent,
      selectors: [["spk-virtual-scroll"]],
      viewQuery: function SparkleVirtualScrollComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuerySignal(ctx.viewportRef, _c48, 5);
          i0.ɵɵviewQuerySignal(ctx.itemElements, _c49, 5);
        }
        if (rf & 2) {
          i0.ɵɵqueryAdvance(2);
        }
      },
      ngContentSelectors: _c0,
      decls: 5,
      vars: 4,
      consts: [["viewport", ""], [1, "viewport", 3, "scroll"], [1, "total-height"], [1, "items-container"]],
      template: function SparkleVirtualScrollComponent_Template(rf, ctx) {
        if (rf & 1) {
          const _r1 = i0.ɵɵgetCurrentView();
          i0.ɵɵprojectionDef();
          i0.ɵɵelementStart(0, "div", 1, 0);
          i0.ɵɵlistener("scroll", function SparkleVirtualScrollComponent_Template_div_scroll_0_listener() {
            i0.ɵɵrestoreView(_r1);
            return i0.ɵɵresetView(ctx.onScroll());
          });
          i0.ɵɵelement(2, "div", 2);
          i0.ɵɵelementStart(3, "div", 3);
          i0.ɵɵprojection(4);
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(2);
          i0.ɵɵstyleProp("height", ctx.totalHeight() + "px");
          i0.ɵɵadvance();
          i0.ɵɵstyleProp("transform", "translateY(" + ctx.translateY() + "px)");
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleVirtualScrollComponent, [{
    type: Component,
    args: [{
      selector: 'spk-virtual-scroll',
      imports: [],
      template: `
    <div class="viewport" #viewport (scroll)="onScroll()">
      <div class="total-height" [style.height]="totalHeight() + 'px'"></div>
      <div class="items-container" [style.transform]="'translateY(' + translateY() + 'px)'">
        <ng-content></ng-content>
      </div>
    </div>
  `,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], null, null);
})();
class SparkleFileDragDropDirective {
  constructor() {
    this.filesOver = signal(false);
    this.filesDropped = output();
  }
  onDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
    this.filesOver.set(true);
  }
  onDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();
    this.filesOver.set(false);
  }
  ondrop(e) {
    e.preventDefault();
    e.stopPropagation();
    this.filesOver.set(false);
    const files = e.dataTransfer?.files;
    if (files && files.length > 0) {
      this.filesDropped.emit(files);
    }
  }
  static {
    this.ɵfac = function SparkleFileDragDropDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparkleFileDragDropDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SparkleFileDragDropDirective,
      selectors: [["", "spkDragDrop", ""]],
      hostVars: 2,
      hostBindings: function SparkleFileDragDropDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("dragover", function SparkleFileDragDropDirective_dragover_HostBindingHandler($event) {
            return ctx.onDragOver($event);
          })("dragleave", function SparkleFileDragDropDirective_dragleave_HostBindingHandler($event) {
            return ctx.onDragLeave($event);
          })("drop", function SparkleFileDragDropDirective_drop_HostBindingHandler($event) {
            return ctx.ondrop($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵclassProp("filesover", ctx.filesOver());
        }
      },
      outputs: {
        filesDropped: "filesDropped"
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparkleFileDragDropDirective, [{
    type: Directive,
    args: [{
      selector: '[spkDragDrop]',
      standalone: true,
      host: {
        '[class.filesover]': 'filesOver()'
      }
    }]
  }], null, {
    onDragOver: [{
      type: HostListener,
      args: ['dragover', ['$event']]
    }],
    onDragLeave: [{
      type: HostListener,
      args: ['dragleave', ['$event']]
    }],
    ondrop: [{
      type: HostListener,
      args: ['drop', ['$event']]
    }]
  });
})();
class SparklePreventWheelDirective {
  wheel(event) {
    event.preventDefault();
  }
  static {
    this.ɵfac = function SparklePreventWheelDirective_Factory(__ngFactoryType__) {
      return new (__ngFactoryType__ || SparklePreventWheelDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: SparklePreventWheelDirective,
      selectors: [["", "spkPreventWheel", ""]],
      hostBindings: function SparklePreventWheelDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("wheel", function SparklePreventWheelDirective_wheel_HostBindingHandler($event) {
            return ctx.wheel($event);
          });
        }
      }
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SparklePreventWheelDirective, [{
    type: Directive,
    args: [{
      selector: '[spkPreventWheel]',
      standalone: true
    }]
  }], null, {
    wheel: [{
      type: HostListener,
      args: ['wheel', ['$event']]
    }]
  });
})();

/*
 * Public API Surface of sparkle-ui
 */

/**
 * Generated bundle index. Do not edit.
 */

export { GridSortableDirective, SparkleAlertComponent, SparkleAlertContainerComponent, SparkleAlertModule, SparkleAlertService, SparkleButtonComponent, SparkleButtonGroupComponent, SparkleCardComponent, SparkleCheckboxComponent, SparkleChipComponent, SparkleColorPickerComponent, SparkleDateRangeInputComponent, SparkleDatepickerComponent, SparkleDatepickerInputComponent, SparkleDialogComponent, SparkleDialogService, SparkleDividerComponent, SparkleFileDragDropDirective, SparkleFileUploadComponent, SparkleFormFieldComponent, SparkleIconComponent, SparkleListComponent, SparkleMenuComponent, SparklePopoverComponent, SparklePreventWheelDirective, SparkleProgressBarComponent, SparkleRadioComponent, SparkleRangeSliderComponent, SparkleResizeDirective, SparkleSelectComponent, SparkleSidenavComponent, SparkleSortDirective, SparkleSortableComponent, SparkleSortableDirective, SparkleSpinnerComponent, SparkleStepperComponent, SparkleTableComponent, SparkleTabsComponent, SparkleToggleComponent, SparkleTooltipComponent, SparkleVirtualScrollComponent, moveIndex };
